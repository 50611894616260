import React from "react";
import audio from '../assets/krzysztof.mp3';
import { MdAudiotrack } from "react-icons/md";

class AudioTest extends React.Component{

  playAudio = () => {
    new Audio(audio).play();
  }

  render() {
    return ( 
          <button onClick={this.playAudio} className="btn btn-sm btn-outline-light pb-2 mb-2" title="Read aloud word Krzysztof"><MdAudiotrack /></button>
 
    );
  }
}

export default AudioTest;