import React from "react";
import P404 from "../pages/P404";
import BetHorse from "../pages/articles/Bethorse-case-study";
import LoyalFoodie from "../pages/articles/Loyalfoodie-case-study";
import Wheretopark from "../pages/articles/Wheretopark-case-study";
import MentorMe from "../pages/articles/Mentorme-case-study";

function renderSwitch({ title }) {
  switch (title) {
    case "bethorse-case-study":
      return <BetHorse />;
    case "loyalfoodie-case-study":
      return <LoyalFoodie />;
    case "wheretopark-case-study":
      return <Wheretopark />;
    case "mentorme-case-study":
      return <MentorMe />;
    default:
      return <P404 />;
  }
}
export default renderSwitch;
