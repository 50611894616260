import React, { Component } from "react";

class LinkedIn extends Component {
  render() {
    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <img
              src="../assets/1553807733020.jpeg"
              className="bg-white border p-2 rounded-circle col-4"
              alt="My face"
            />
          </div>
          <h4 className="col-12 text-center mt-2">Krzysztof Dabrowski</h4>
        </div>

        <div className="row justify-content-center gap-2 py-4">
          <div className="col-10 col-md-5 mx-1 my-1 px-3 bg-white rounded border shadow-sm my-2 pb-2">
            <p className="border-bottom py-2">My education</p> 
            <div className="card mb-2 mt-3 border-0">
                  <div className="row mx-1">
              <div className="col-2 pr-0">
                <img
                  src="../assets/TH_Koeln_Logo.svg"
                  className="col-12 p-0 mt-2"
                  alt="Cologne University of Applied Sciences"
                />
              </div>
              <div className="col">
                <h4 className="mb-0">Web Science, M.Sc.</h4>
                <small className="text-muted">2019 – 2021</small>
                <p>Cologne University of Applied Sciences</p>
              </div>
              </div>
              </div>
              <div className="card mb-2 mt-3 border-0">
                  <div className="row mx-1">
              <div className="col-2 pr-0">
                <img
                  src="../assets/640px-Logo_Pg_en.jpg"
                  className="col-12 p-0 mt-2"
                  alt="Gdańsk University of Technology"
                />
              </div>
              <div className="col">
                <h4 className="mb-0">Mechatronics, B.Sc.</h4>
                <small className="text-muted">2012 – 2016</small>
                <p>Gdańsk University of Technology</p>
              </div></div>
            </div>
          </div>
          <div className="col-10 col-md-5 mx-1 my-1 px-3 bg-white rounded border shadow-sm my-2 pb-2">
            <p className="border-bottom py-2">My current job</p>
            <div className="card mb-2 mt-3 border-0">
                  <div className="row mx-1">
              <div className="col-2 pr-0">
                <img
                  src="../assets/Procter_&_Gamble_logo.svg"
                  className="col-12 p-0 mt-2"
                  alt="Procter and Gamble Logo"
                />
              </div>
              <div className="col">
                <h4 className="mb-0">Electrical and Software Equipment Owner</h4>
                <small className="text-muted">2018 – today</small>
                <p>Procter and Gamble, Germany</p>
              </div>
              </div>
              </div>
          </div>
          <div className="col-10 mx-1 my-1 px-3 bg-white rounded border shadow-sm my-2 pb-2">
            <p className="border-bottom py-2">My latest certificates and trainings</p>
            <div className="card mb-2 mt-3 border-0">
                  <div className="row border-bottom mx-1">
              <div className="col-2 col-md-1 pr-0">
                <img
                  src="../assets/linux.png"
                  className="col-12 p-0 mt-0 rounded"
                  alt="Linux Foundation Logo"
                />
              </div>
              <div className="col">
                <h4 className="mb-0">Kubernetes Developer Bundle (CKAD)</h4>
                <small className="text-muted">August 2021</small>
                <p>LinuxFoundation</p>
              </div>
              </div>
              </div>
            <div className="card mb-2 mt-3 border-0">
                  <div className="row border-bottom mx-1">
              <div className="col-2 col-md-1 pr-0">
                <img
                  src="../assets/goethe.png"
                  className="col-12 p-0 mt-0 rounded"
                  alt="Goethe Institute Logo"
                />
              </div>
              <div className="col">
                <h4 className="mb-0">Goethe-Zertifikat C1</h4>
                <small className="text-muted">July 2021</small>
                <p>Goethe-Institut e.V.</p>
              </div>
              </div>
              </div>
            <div className="card mb-2 mt-3 border-0">
                  <div className="row border-bottom mx-1">
              <div className="col-2 col-md-1 pr-0">
                <img
                  src="../assets/coursera.jpeg"
                  className="col-12 p-0 mt-0 rounded"
                  alt="Coursera Logo"
                />
              </div>
              <div className="col">
                <h4 className="mb-0">Google UX Certificate</h4>
                <small className="text-muted">April 2021</small>
                <p>Coursera</p>
              </div>
              </div>
              </div> 
          </div>
        </div>
      </>
    );
  }
}

export default LinkedIn;
