import React from 'react';
import ItemsList from './ItemsList';
import { Link } from "react-router-dom";

const ShowOthers = ({title}) => {  
  const otherContent = ItemsList.filter(article => article.address !== title);
  console.log(title);
 return (
  otherContent.slice(otherContent.length-2, otherContent.length).map((article,key)=> (   
    <div className="card border-0 col-12 col-md-5 mx-1 my-1 px-0 shadow-sm rounded"> 
      <div className="card-body px-3 bg-white rounded-bottom">
        <h5 className="card-title">{article.title}</h5>
        <p className="card-text">{article.description.substring(0,250)}...</p> 
        <Link key={key} to={`/case-study/${article.address}`} >
            <button className="btn btn-secondary">Go there</button>
        </Link>
      </div>
    </div>  
  ))
  )
};
 
export default ShowOthers;