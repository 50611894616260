import React from "react";  

class GenerateCurves extends React.Component {
  render() {
    return (
      <svg id="morph-trans"
        viewBox="0 0 900 600" 
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        version="1.1" className="position-absolute"
      >
        <path id="w11"
          d="M0 97L25 114C50 131 100 165 150 176C200 187 250 175 300 167C350 159 400 155 450 143C500 131 550 111 600 107C650 103 700 115 750 127C800 139 850 151 875 157L900 163L900 0L875 0C850 0 800 0 750 0C700 0 650 0 600 0C550 0 500 0 450 0C400 0 350 0 300 0C250 0 200 0 150 0C100 0 50 0 25 0L0 0Z"
          fill="#394c82"
        ></path>
        <path id="w12"
          d="M0 127L25 148C50 169 100 211 150 225C200 239 250 225 300 217C350 209 400 207 450 194C500 181 550 157 600 153C650 149 700 165 750 177C800 189 850 197 875 201L900 205L900 161L875 155C850 149 800 137 750 125C700 113 650 101 600 105C550 109 500 129 450 141C400 153 350 157 300 165C250 173 200 185 150 174C100 163 50 129 25 112L0 95Z"
          fill="#3f548f"
        ></path>
        <path id="w13"
          d="M0 205L25 220C50 235 100 265 150 281C200 297 250 299 300 298C350 297 400 293 450 274C500 255 550 221 600 218C650 215 700 243 750 260C800 277 850 283 875 286L900 289L900 203L875 199C850 195 800 187 750 175C700 163 650 147 600 151C550 155 500 179 450 192C400 205 350 207 300 215C250 223 200 237 150 223C100 209 50 167 25 146L0 125Z"
          fill="#455c9d"
        ></path>
        <path id="w14"
          d="M0 235L25 252C50 269 100 303 150 319C200 335 250 333 300 334C350 335 400 339 450 325C500 311 550 279 600 272C650 265 700 283 750 295C800 307 850 313 875 316L900 319L900 287L875 284C850 281 800 275 750 258C700 241 650 213 600 216C550 219 500 253 450 272C400 291 350 295 300 296C250 297 200 295 150 279C100 263 50 233 25 218L0 203Z"
          fill="#4c64aa"
        ></path>
        <path id="w15"
          d="M0 409L25 408C50 407 100 405 150 412C200 419 250 435 300 448C350 461 400 471 450 464C500 457 550 433 600 423C650 413 700 417 750 416C800 415 850 409 875 406L900 403L900 317L875 314C850 311 800 305 750 293C700 281 650 263 600 270C550 277 500 309 450 323C400 337 350 333 300 332C250 331 200 333 150 317C100 301 50 267 25 250L0 233Z"
          fill="#576eb5"
        ></path>
        <path id="w16"
          d="M0 481L25 474C50 467 100 453 150 457C200 461 250 483 300 495C350 507 400 509 450 501C500 493 550 475 600 470C650 465 700 473 750 475C800 477 850 473 875 471L900 469L900 401L875 404C850 407 800 413 750 414C700 415 650 411 600 421C550 431 500 455 450 462C400 469 350 459 300 446C250 433 200 417 150 410C100 403 50 405 25 406L0 407Z"
          fill="#677bbc"
        ></path>
        <path id="w17"
          d="M0 523L25 526C50 529 100 535 150 540C200 545 250 549 300 551C350 553 400 553 450 555C500 557 550 561 600 559C650 557 700 549 750 543C800 537 850 533 875 531L900 529L900 467L875 469C850 471 800 475 750 473C700 471 650 463 600 468C550 473 500 491 450 499C400 507 350 505 300 493C250 481 200 459 150 455C100 451 50 465 25 472L0 479Z"
          fill="#7688c3"
        ></path>
        <path id="w18"
          d="M0 601L25 601C50 601 100 601 150 601C200 601 250 601 300 601C350 601 400 601 450 601C500 601 550 601 600 601C650 601 700 601 750 601C800 601 850 601 875 601L900 601L900 527L875 529C850 531 800 535 750 541C700 547 650 555 600 557C550 559 500 555 450 553C400 551 350 551 300 549C250 547 200 543 150 538C100 533 50 527 25 524L0 521Z"
          fill="#8595ca"
        ></path>

<path id="w21" className="d-none" d="M0 115L25 112C50 109 100 103 150 118C200 133 250 169 300 182C350 195 400 185 450 178C500 171 550 167 600 167C650 167 700 171 750 180C800 189 850 203 875 210L900 217L900 0L875 0C850 0 800 0 750 0C700 0 650 0 600 0C550 0 500 0 450 0C400 0 350 0 300 0C250 0 200 0 150 0C100 0 50 0 25 0L0 0Z" fill="#394c82"></path>
<path id="w22" className="d-none" d="M0 193L25 186C50 179 100 165 150 171C200 177 250 203 300 216C350 229 400 229 450 232C500 235 550 241 600 247C650 253 700 259 750 267C800 275 850 285 875 290L900 295L900 215L875 208C850 201 800 187 750 178C700 169 650 165 600 165C550 165 500 169 450 176C400 183 350 193 300 180C250 167 200 131 150 116C100 101 50 107 25 110L0 113Z" fill="#3f548f"></path>
<path id="w23" className="d-none" d="M0 229L25 228C50 227 100 225 150 232C200 239 250 255 300 263C350 271 400 271 450 276C500 281 550 291 600 303C650 315 700 329 750 331C800 333 850 323 875 318L900 313L900 293L875 288C850 283 800 273 750 265C700 257 650 251 600 245C550 239 500 233 450 230C400 227 350 227 300 214C250 201 200 175 150 169C100 163 50 177 25 184L0 191Z" fill="#455c9d"></path>
<path id="w24" className="d-none" d="M0 259L25 256C50 253 100 247 150 255C200 263 250 285 300 294C350 303 400 299 450 300C500 301 550 307 600 319C650 331 700 349 750 352C800 355 850 343 875 337L900 331L900 311L875 316C850 321 800 331 750 329C700 327 650 313 600 301C550 289 500 279 450 274C400 269 350 269 300 261C250 253 200 237 150 230C100 223 50 225 25 226L0 227Z" fill="#4c64aa"></path>
<path id="w25" className="d-none" d="M0 331L25 326C50 321 100 311 150 321C200 331 250 361 300 374C350 387 400 383 450 383C500 383 550 387 600 400C650 413 700 435 750 442C800 449 850 441 875 437L900 433L900 329L875 335C850 341 800 353 750 350C700 347 650 329 600 317C550 305 500 299 450 298C400 297 350 301 300 292C250 283 200 261 150 253C100 245 50 251 25 254L0 257Z" fill="#576eb5"></path>
<path id="w26" className="d-none" d="M0 487L25 496C50 505 100 523 150 524C200 525 250 509 300 500C350 491 400 489 450 483C500 477 550 467 600 472C650 477 700 497 750 508C800 519 850 521 875 522L900 523L900 431L875 435C850 439 800 447 750 440C700 433 650 411 600 398C550 385 500 381 450 381C400 381 350 385 300 372C250 359 200 329 150 319C100 309 50 319 25 324L0 329Z" fill="#677bbc"></path>
<path id="w27" className="d-none" d="M0 547L25 549C50 551 100 555 150 552C200 549 250 539 300 536C350 533 400 537 450 535C500 533 550 525 600 524C650 523 700 529 750 539C800 549 850 563 875 570L900 577L900 521L875 520C850 519 800 517 750 506C700 495 650 475 600 470C550 465 500 475 450 481C400 487 350 489 300 498C250 507 200 523 150 522C100 521 50 503 25 494L0 485Z" fill="#7688c3"></path>
<path id="w28" className="d-none" d="M0 601L25 601C50 601 100 601 150 601C200 601 250 601 300 601C350 601 400 601 450 601C500 601 550 601 600 601C650 601 700 601 750 601C800 601 850 601 875 601L900 601L900 575L875 568C850 561 800 547 750 537C700 527 650 521 600 522C550 523 500 531 450 533C400 535 350 531 300 534C250 537 200 547 150 550C100 553 50 549 25 547L0 545Z" fill="#8595ca"></path>

      </svg> 
    );
  }
}

export default GenerateCurves;
