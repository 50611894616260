const ItemsList = [
{ 
    title: 'LoyalFoodie',
    address: 'loyalfoodie-case-study',
    description: 'A case study of loyalty program aimed for food trucks clients',
    image: 'works/loyalfoode-case-study/hero.png',
},
{  
    title: 'MentorMe',
    address: 'mentorme-case-study',
    description: 'A case study of application for online university mentoring',
    image: 'works/mentorme-case-study/hero.png',
},
{ 
    title: 'WhereToPark?',
    address: 'wheretopark-case-study',
    description: 'A case study of application for planning parks',
    image: 'works/wheretopark-case-study/hero.png',
},
{ 
    title: 'BetHorse',
    address: 'bethorse-case-study',
    description: 'A case study of application for horse betting using cryptocurrency',
    image: 'works/betonyourhorse-case-study/hero.png',
}
];
export default ItemsList;