import React from 'react';
import ShowWorks from '../components/ShowWorks'; 

const AllWorks = () => ( 
<>
      
<div className="containter bg-dark border-top border-bottom p-4 p-md-5">
      <h1 className="text-white text-center pt-3 pb-4">All works and projects</h1>
  <div className="row justify-content-center pb-5">
  <ShowWorks/>
    </div>
    </div>
</>
);
 
export default AllWorks;