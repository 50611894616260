import React from 'react';  

const BetHorse = () => {

    return (
    <>
    <div className="rounded bg-hero shadow-sm row p0 mb-4 overflow-hidden" >
    <div className="col-6 pt-5 px-5">
        <small className="text-muted mb-0">Case Study</small>
        <h1 className="display-4">BetHorse</h1>
        <p className="lead mb-0">May 2021</p> 
    </div>
    <div className="col-6 mt-3 position-relative hero "> 
            <img src="../../assets/works/betonyourhorse-case-study/hero.png" alt="Mockup showing one of application screens" /> 
    </div>  
</div>  
<div className="row">

<div className="col-8 offset-2">
  <div className="row justify-content-center py-5 ">
    <div className="col-4 col-lg-3"><img src="../../assets/works/betonyourhorse-case-study/TH_Koeln_Logo.svg"
        className="mt-4 col-12 col-md-8 offset-md-2" alt="Cologne University of Applied Science Logo" /></div>
    <div className="col">
      <p className="lead px-3  justify-content-center">
        This project was partially created during Web Trust course of Web Science program at Cologne University of
        Technology, together with Franziska Schmidt and Sandeep Gantait. I decided to continue the project and
        move
        design process forward.
      </p>
    </div>
  </div>
</div>

</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3 className="pb-3">
    Welcome to BetHorse
    <small className="text-muted ml-2">Introduction</small>
  </h3>
  <p>I would like to share with you interesting concept of application that crushes traditional appeal of horse
    racing sport. Over past months this concept was growing and evolving into shape you can see today.
  </p>

</div>
</div>

<div className="row justify-content-center py-4">
<div className="col-md-8 justify-content-center py-3">
  <dl className="row">
    <dt className="col-sm-3 ">My role</dt>
    <dd className="col-sm-9">
      <p>Together with Sandeep and Franziska we created
        trust analysis, concept of blockchain use and drafted architecture of the solution. Then I continued
        project alone taking role of UX researcher and designer.</p>
    </dd>
  </dl>
  <dl className="row">
    <dt className="col-sm-3">Project timings</dt>
    <dd className="col-sm-9">October 2020 - February 2021; May 2021</dd>
  </dl>
</div>
</div>

<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3>The Problem</h3>
  <p>Horse racing and betting are traditional sport and hermetic community comparing to other betting systems or
    sports. Some say it is reserved nowadays for elites, despite moveis like the sting building different image.
  </p>
  <p>Generally horse racing betting has few severe pain points:</p>
  <ul>
    <li>Users were reporting that betting companies would not accept claims if they made errors.</li>
    <li>Long queues preventing from making the last moment bets</li>
    <li>Sometimes users were placing bets in milliseconds after the race started. Timing issues were usually
      resolved in favor of the betting company or bet was cancelled</li>
    <li>Calculations and status of the budget is challenging for most users</li>
    <li>Due to the manual process of accepting the bet most popular races are offered with high processing fees
    </li>
  </ul>
</div>
</div>

<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h1>The Goal</h1>
  <p>The goal is to create an application that would merge two different worlds together - horse racing and
    cryptocurrency.</p>
</div>
</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3>Technical Solution</h3>
  <p>Team assumed that most of the horse racing tracks are using commercial horse race tracking systems. As based
    analyzed was the technology behind the most popular one, British TurfTrax. It is using a database where scores
    are stored. Our application backend would source this information using microservices.</p>
  <p>Team would base the cryptocurrency model using a commercial solution, Etherum. It is the most commonly used
    currency in gambling category (based on own research among industry). The use of Etherum would allow to focus
    on other aspects of the system and application. Overall it was talked that usually horsing bets are done
    within the day of the race, usually shortly before. Short timespan would avoid fear of currency value
    fluctuations. To reduce the fear among betters against the cryptocurrency fluctuations application could
    partially insure the currency rate when it drops below a certain threshold. Example would be if Ethereum would
    have lost 20 percent of value over 24 hours at the time users would sell.</p>
  <p>Application would store Ethereum coins of users and allow them to place bets on horse races that our system
    has access to. Smart contract would be utilized as an overall container for transaction for each bet. Smart
    contracts would be set when the user is placing a bet. It would be resolved once the score is registered in
    our database. In case of score change, horse disqualification or race cancellation respective policies must be
    established.</p>
  <p>Application would not take care of exchanging cryptocurrency. A 3rd party application would be recommended
    for currency purchase and selling.</p>
</div>
</div>
<div className="row mb-4">
<div className="col-12 bg-white rounded border shadow-sm overflow-auto slowd-box text-center">
  <img src="../../assets/works/betonyourhorse-case-study/blockchain-diagram.svg" className="" alt="Solution overview" />
</div>
<figcaption className="blockquote-footer">
  Overview of proposed solution
</figcaption>
</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3>Blockchain flow</h3>
  <ul>

    <li><b>Better's Wallet</b> - All the users(people who would bet on the horses) of the system would have to
      convert their money(currency) into cryptocurrency like bitcoins, Ethereum or litecoins - use of
      cryptocurrencies ensures anonymity and ease of registration. The wallet would be managed by the 3rd party
      service provider like myCrypo which has various features like exchanging money with crypto, additional
      security to wallet using proprietary external hardware, etc.</li>
    <li><b>Smart Contracts</b> - Smart contracts are the lines of codes that are stored in blockchain and
      automatically execute when predetermined terms and conditions are met. So the idea is when the users would
      like to bet on a horse, certain amount of cryptocurrencies will be deducted from their wallet, and based on
      the predetermined conditions the funds would be released to the winner's wallet.As defined by the
      blockchain, there would be distributed ledger which would be saved in the participating miners. Each of the
      miners would gain in the form of real current monetary value of the mined cryptocurrency.</li>
    <li><b>Horse Tracking data acquisitions</b> - The positions of the horse would be determined by various modern
      sensors (mTag, Ftag, FRX), which allows minimum human interventions. These sensors would generate data,
      which would be accessed by the system using various access points.</li>
    <li><b>Race servers</b> - The generated data from the above mentioned sensors would go to the Race servers
      where a predetermined algorithm would decide the winners. The server would also be responsible for the
      better data which includes their basic information and the horse on which they had bet on.</li>
    <li><b>Race database</b> - Though the main ledger would be saved in the distributed ledger of blockchain, the
      better's and winner's information would also be saved in the system's database.</li>
    <li><b>Scoring API</b> - The system's data would finally be accessed by the help of Application Programming
      Interface. API would be used to create smart contracts, which would be used to trigger the smart contract's
      condition, which further triggers the disperse of cryptocurrency to the winner's wallet.</li>
  </ul>


  <p>The process of creation and flow of blockchain coin as presented of figure:</p>
  <ul>
    <li>Suppose User A came in to the system and wanted to buy 2 Ethereum from a 3rd party crypto provider
      myCrypto</li>
    <li>User uses a private key and signs a message requesting the required number of Ethereum from the 3rd party
      provider</li>
    <li>The network of nodes validates the User A's transaction using an algorithm in a process called mining</li>
    <li>The first miner to validated a new block for the blockchain receives a portion of the Ethereum as a reward
    </li>
    <li>The transaction is complete and the new block is added to the blockchain, the block is permanent and can
      not be modified</li>
    <li>At the end User A receives 2 coins of Ethereum</li>
  </ul>
</div>
</div>
<div className="row mb-4">
<div className="col-12 bg-white rounded border shadow-sm overflow-auto slowd-box text-center">
  <img src="../../assets/works/betonyourhorse-case-study/blockchain-diagram-2.svg" className=""
    alt="Focus on flow of blockchain" />
</div>
<figcaption className="blockquote-footer">
  Flow of blockchain in the application (by Sandeep)
</figcaption>
</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3>Architecture of the solution</h3>
  <p>In order to fulfill the availability target team decided to create a geo-redundant core of application to
    enable users to use it when other regions would be affected by any issue. To speed-up the loading process we
    would use a commercial load balancer and try to source most assets via CDN.</p>
  <p>It was decided to use a commercial solution for user login and access management as an active directory.
    Blockchain-based coins would be stored in a key vault to increase their safety.</p>
  <p>To attract more users application would allow users to use external identity providers, like Google or
    Amazon. That would trigger respective processes to the active directory.</p>
  <p>In case of control or technical failure application would introduce extensive back-up practice, keeping
    transnational records for at least 5 years, as part of the archive storage.</p>
</div>
</div>
<div className="row mb-4">
<div className="col-12 bg-white rounded border shadow-sm overflow-auto slowd-box">
  <img src="../../assets/works/betonyourhorse-case-study/blockchain-diagram-1.svg" className="col-12"
    alt="Architecture overview" />

  <div className="col-12 px-4 py-2">
    <div className="row">
      <h4 className="text-left mt-3">Legend</h4>
    </div>
    <div className="row">
      <span className="col border text-dark py-2 rounded mr-2">Geo-replicated
        resources</span>
      <span className=" col border text-dark py-2 rounded">Not geo-replicated
        resources</span>
    </div>
  </div>
</div>
<figcaption className="blockquote-footer">
  Overview of solution architecture
</figcaption>
</div>



<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3>Personas</h3>
  <h4 className="text-center my-3">George</h4>
  <div className="row justify-content-center">
    <div className="col-sm-9 col-lg-6 mr-2">
      <dl className="row">
        <dt className="col-sm-4">Age</dt>
        <dd className="col">36</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4">Education</dt>
        <dd className="col">B.A. in Graphic Design</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4">Hometown</dt>
        <dd className="col">Glasgow, Ireland</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4">Family</dt>
        <dd className="col">Married, son</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4">Occupation</dt>
        <dd className="col">Not employed</dd>
      </dl>
    </div>
    <div className="col-10 col-lg-4">
      <img src="../../assets/works/betonyourhorse-case-study/sigmund-QjCNk0TxGfc-unsplash.jpg"
        className="col px-1 py-1 img-thumbnail" alt="Persona of George" />

    </div>
  </div>
  <figure className="text-center">
    <blockquote className="blockquote my-5">
      <p>“This time it has to work out”</p>
    </blockquote>
  </figure>
  <div className="row justify-content-center pb-5 border-bottom">
    <div className="col bg-white border px-3 py-3 rounded mr-2">
      <p>George was going to horse race track since he was small, with his father. Whenever he is coming back to
        the race track that resembles him of his father and all good memories.</p>
      <p>George is casual bidder and usually visits race track once or twice a year. Daily he is working as
        construction worker trying to save money to buy own home.</p>
    </div>
    <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
      <h4 className="text-success">Goals</h4>
      <ul>
        <li>Wants to be free and independent</li>
        <li>Wants to earn decent money</li>
        <li>Wants to work hard to make for his living</li>
      </ul>
      <h4 className="text-danger">Frustrations</h4>
      <ul>
        <li>Working is hard and not paying-off</li>
        <li>You need a glimpse of luck to get rich</li>
        <li>He cannot figure out what horse to put stake into</li>
      </ul>
    </div>
  </div>
</div>
</div>
<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h4 className="text-center my-3">Sigmund</h4>
  <div className="row justify-content-center">
    <div className="col-sm-9 col-lg-6 mr-2">
      <dl className="row">
        <dt className="col-sm-4">Age</dt>
        <dd className="col">54</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4 ">Education</dt>
        <dd className="col">High School diploma</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4 ">Hometown</dt>
        <dd className="col">Cologne, Germany</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4 ">Family</dt>
        <dd className="col">Married</dd>
      </dl>
      <dl className="row">
        <dt className="col-sm-4 ">Occupation</dt>
        <dd className="col">Technician in factory</dd>
      </dl>
    </div>
    <div className="col-10 col-lg-4">
      <img src="../../assets/works/betonyourhorse-case-study/foto-sushi-6anudmpILw4-unsplash.jpg"
        className="col px-1 py-1 img-thumbnail" alt="Persona of Sigmund" />

    </div>
  </div>
  <figure className="text-center">
    <blockquote className="blockquote my-5">
      <p>“I am very excited that season has already started. I hope my bets will work-out“</p>
    </blockquote>
  </figure>
  <div className="row justify-content-center pb-5 border-bottom">
    <div className="col bg-white border px-3 py-3 rounded mr-2">
      <p>Sigmund is saavy horse racing gambler. In past 20 years he had ups and downs but he always managed to go
        back on track thanks to his passion - horses. Sigfried is not enjoying his work and opportunity to visit
        horse track is a pleasure for him.</p>
    </div>
    <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
      <h4 className="text-success">Goals</h4>
      <ul>
        <li>Wants to enjoy life</li>
        <li>Wants to see beautiful sport of horse racing</li>
        <li>Wants to win enough money to retire</li>
      </ul>
      <h4 className="text-danger">Frustrations</h4>
      <ul>
        <li>Not everything goes his way</li>
        <li>Betting rates are not honest</li>
        <li>Information flow with horse racing teams is not clear</li>
      </ul>
    </div>
  </div>
</div>
</div>



<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">

  <h3>Problems Statements</h3>
  <p>By applying observed horse better pain points following Problem Statements were formed:</p>
  <p><u className="text-primary">George</u> is a <u className="text-danger">casual horse better</u> who needs <u
      className="text-success">a
      easy way to recognize potential winners</u> because <u className="text-warning">there is not other way to
      understand chances himself</u>.</p>
  <p><u className="text-primary">Sigmund</u> is a <u className="text-danger">horse racing geek</u> who needs <u
      className="text-success">to quickly understand what his situation and chances</u> becuase <u
      className="text-warning">horses and horse racing are his hobbies</u>.</p>
</div>
</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">
  <h3>Trust Analysis</h3>
  <p>Focus is going to be on the relationship between betters and BetOnYourHorse team and application. Application
    has a direct impact on this relationship and it is the most critical relationship for application success.</p>
  <p>Due to specifics of analysed relationship it was decided to use Corritere et al model of trust analysis:</p>

  <div className="row mb-4">
    <div className="col-12 bg-white rounded border shadow-sm overflow-auto slowd-box">
      <div className="row my-4 mx-2 d-flex align-items-center">
        <div className="col-3">
          <div className="col-12 d-flex my-3">
            <div className="arrow-bg bg-light rounded-start"><small className="p-1">Crypto Craze</small></div>
            <div className="arrow-right"></div>
          </div>
          <div className="col-12 d-flex my-3">
            <div className="arrow-bg bg-light rounded-start"><small className="p-1">Horse-racing</small></div>
            <div className="arrow-right"></div>
          </div>
          <div className="col-12 d-flex my-3">
            <div className="arrow-bg bg-light rounded-start"><small className="p-1">Betting habits</small></div>
            <div className="arrow-right"></div>
          </div>
          <div className="col-12 my-4 position-relative">
            <small>External factors</small>
            <div className="icon external">
              <div className="arrow"></div>
            </div>
          </div>
          <div className="col-12 d-flex my-3">
            <div className="arrow-bg bg-light rounded-start"><small className="p-1">Competition</small></div>
            <div className="arrow-right"></div>
          </div>
          <div className="col-12 d-flex my-3">
            <div className="arrow-bg bg-light rounded-start"><small className="p-1">Economy</small></div>
            <div className="arrow-right"></div>
          </div>
          <div className="col-12 d-flex my-3">
            <div className="arrow-bg bg-light rounded-start"><small className="p-1">Regulations</small></div>
            <div className="arrow-right"></div>
          </div>
        </div>
        <div className="col-7 border rounded dotted">
          <p className="mb-0 pt-2">Perception of:</p>
          <div className="col-12 border border-2 rounded my-2 py-2 px-3">
            <p className="mb-0">Credibility</p>
            <span className="badge bg-light text-dark">Design quality</span>
            <span className="badge bg-light text-dark">Transparency</span>
            <span className="badge bg-light text-dark">Recommendation</span>
            <span className="badge bg-light text-dark">UX</span>
            <span className="badge bg-light text-dark">Insurance</span>
            <span className="badge bg-light text-dark">Security</span>
            <span className="badge bg-light text-dark">Availability</span>
          </div>
          <div className="col-12 border border-2 rounded my-2 py-2 px-3 position-relative">
            <div className="icon easy-top">
              <div className="arrow"></div>
            </div>
            <div className="icon easy-bottom">
              <div className="arrow"></div>
            </div>
            <div className="easy-right">
              <div className="top-filler"></div>
              <div className="center-filler"></div>
              <div className="icon ">
                <div className="arrow"></div>
              </div>
            </div>
            <p className="mb-0">Ease of use</p>
            <span className="badge bg-light text-dark">Design quality</span>
            <span className="badge bg-light text-dark">UX</span>
            <span className="badge bg-light text-dark">Management</span>
            <span className="badge bg-light text-dark">Easy exchange</span>
            <span className="badge bg-light text-dark">Search & selection</span>
            <span className="badge bg-light text-dark">Time</span>
          </div>
          <div className="col-12 border border-2 rounded my-2 py-2 px-3">
            <p className="mb-0">Risk</p>
            <span className="badge bg-light text-dark">Unavailability</span>
            <span className="badge bg-light text-dark">Exchange rate</span>
            <span className="badge bg-light text-dark">Data and privacy loss</span>
            <span className="badge bg-light text-dark">Security flaws</span>
            <span className="badge bg-light text-dark">Dumping prices</span>
            <span className="badge bg-light text-dark">Profits and taxes</span>
          </div>
        </div>
        <div className="col-2 d-flex align-items-center position-relative">
          <div className="icon trust-top">
            <div className="arrow"></div>
          </div>
          <div className="icon trust-bottom">
            <div className="arrow"></div>
          </div>
          <p className="col-12 text-center">Trust</p>
        </div>
      </div>
    </div>
    <figcaption className="blockquote-footer">
      Relationship analysis based on Corritere et al model
    </figcaption>
  </div>

  <p>External factors which would affect the application:</p>
  <ul>
    <li><b>Crypto Craze</b>. Cryptocurrencies are a relatively new instrument of exchange. Looking at the
      example
      of Bitcoin, media and community reaction, people may fear to use such unstable and unofficially regulated
      currency.</li>
    <li><b>Horse-racing sport and industry</b>. Since no one yet introduced a commercial system based only on
      cryptocurrencies the impact on and from the horse-racing community is not known.</li>
    <li><b>Betting habits</b>. Most people are not understanding yet the idea of cryptocurrency and its
      benefits.
      They like to have a piece of printed bet in their hands, regardless what is the risk of not getting money.
    </li>
    <li><b>Competition</b>. Horse-racing is heavily monopolized business in many countries. Very often the
      license
      is granted to 1 company per track and race. Introduction of BetOnYourHorse would be like the fight between
      Goliath and David, being small and new has some advantages.</li>
    <li><b>Economy</b>. It is known that downfalls in economic status are resulting in bigger interest in
      betting
      and overall high-risk investments. </li>
    <li><b>Regulations and taxations</b>. Regulations of trading cryptocurrencies are regulated differently from
      country to country, often staying in grey-zone due no regulations. There are certain aspects like income
      tax
      but there is no overall dependency of national banks and monetary institutions. Horse-racing is a heavily
      regulated market, where often there is one company providing services within the country.</li>
  </ul>

  <p>Perception of Credibility: </p>
  <ul>
    <li><b>Design quality</b>. Well designed and designed consistent system would have much higher credibility
      perception.</li>
    <li><b>Transparency</b>. Customers (betters) could understand the flow of their money. The overall process
      of
      creating smart contracts need to be transparent and visible to all stakeholders to build a high perception
      of credibility.</li>
    <li><b>Recommendation</b>. Recommendation from a trusted body of a person would heavily impact perception of
      credibility. It should be considered via recommendation system between users.</li>
    <li><b>UX</b>. Overall experience seems to be significant in order to keep user and be perceived as
      credible.
    </li>
    <li><b>Insurance</b>. As described in earlier parts application should offer insurance for extreme
      conditions
      to address fears of some users. Sourcing insurance from well-known and trusted companies would improve
      perception of credibility of BetOnYourHorse application.</li>
    <li><b>Security</b>. Security measures, their communication and transparency would impact perception of
      credibility.</li>
    <li><b>Availability</b>. Consistent availability and performance are a must for application to be credible.
      Discussed application is time sensitive so users would not accept any issues in this area.</li>
  </ul>

  <p>Perception of Easiness of Use:</p>
  <ul>
    <li><b>Design quality</b>. Overall design quality must be high in order to attract many customers and allow
      them to onboard quickly and easily.</li>
    <li><b>UX</b>. User Experience need to be flawless in order to be appreciated over existing paper- and
      money-based betting solutions.</li>
    <li><b>Time</b>. As mentioned in availability point, horse-racing is a very time sensitive sport. Betters
      are deciding within seconds, or they are awaiting for another race to be over to get funds. Easy planning,
      access and overall betting process is a must for perceived easiness of use.</li>
    <li><b>Easy exchange</b>. Funds transfer must be easy (and fast) to keep users in the application.</li>
    <li><b>Search and selection</b>. Process of finding races and creating bets is most time critical so must be
      extremely well thought and designed.</li>
    <li><b>Management</b>. Management of accounts, funds and overall performance tracking is very important to
      give users a good feeling of what they have and what they could do. Perhaps tips on betting patterns could
      improve perceived easiness of use.</li>
  </ul>


  <p>Perception of Risk:</p>
  <ul>
    <li><b>Unavailability</b>. As mentioned before, betting is time sensitive activity. Not communicated and not
      visible delay may cause no successful bet and therefore may cause a lawsuit due to lost profit.</li>
    <li><b>Exchange rate</b>. Cryptocurrencies are considered a high risk instrument. </li>
    <li><b>Data and privacy loss</b>. Assuming users would be very angry if their bets, wallets and betting
      patterns would be leaked outside of the application. Potentially some of them would try to do money
      laundry
      within the application, therefore leak of data may end up in court.</li>
    <li><b>Security flaws</b>. If security flaws would be noticed that may cause issues with the regulatory
      body,
      resulting in fine or even prison. Exploited flaws may result in data loss and therefore potentially bad
      press, lawsuits and loss of customers.</li>
    <li><b>Dumping prices</b>. As mentioned earlier, the market of horse-racing betting is often monopolized. It
      may be very easy for current behemoths to dump winning rates and make us lose money. At the end betters
      would go where best rates are.</li>
    <li><b>Profits and taxes</b>. Cryptocurrencies and not well regulated area so it is puzzling how to tax some
      operations. In case of regulations change, the company and users may be forced to shutdown, pay fines or
      cover missing taxes.</li>
  </ul>





  <p><b>How is trust working? What improved with this app?</b></p>
  <p>BetOnYourHorse makes horse-race betting more transparent, faster and less costly. Together with aesthetic
    appealing design and clear communication of bet status and coin overview the application would help to heal
    the relationship between customers and bet providing body. </p>
  <p>The application is eliminating the issue of not paid win or not taken bet due to organizational, technical
    issues or just the will of traditional betting companies. BetOnYourHorse is automatically provision amount
    of
    cryptocurrency as a part of smart contract, securing the win of the user. </p>
  <p>Once betters would recognize and appreciate the benefits of the solution, the trust would be built. Then it
    is important to preserve it by complying with regulations and maintaining infrastructure in good health.</p>



</div>
</div>


<div className="row justify-content-center">
<div className="col-md-8 pt-3">

  <h3>Initial wireframes</h3>

  <dl className="row mt-3 pt-2 border rounded">
    <dt className="col-7 my-2">Project with wireframes</dt>
    <dd className="col d-grid  justify-content-center"><a
        href="https://glanonim.com/assets/works/betonyourhorse-case-study/BetHorse-Wireframes.xd"
        target="_blank" rel="noreferrer"><button type="button" className="btn btn-dark btn-block">Download Xd file</button></a></dd>
  </dl>
</div>
</div>
<p className="mt-3">Overview of wireframes created in Adobe XD:</p>
<div className="row">
<div className="col-12 bg-adobexd rounded border shadow-sm overflow-auto slowd-box text-center">
  <img src="../../assets/works/betonyourhorse-case-study/wireframes.png" className="" alt="Wireframes overview" />
</div>
</div>

<div className="row my-4 justify-content-center">
<div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm  mr-2">
  <div className="row py-3 px-2">
    <div className="col">
      <h5>Homepage wireframe</h5>
      <p>Homepage is the center of the application. User may see here what races are coming up or how his
        financial status is looking like.</p>
    </div>
    <div className="col-6 col-lg-5">
      <img src="../../assets/works/betonyourhorse-case-study/wireframes-start.png" className="img-fluid rounded"
        alt="Wireframe of homepage screen" />
    </div>
  </div>
</div>
</div>
<div className="row my-4 justify-content-center">
<div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm mr-2">
  <div className="row py-3 px-2">
    <div className="col">
      <h5>Horse history wireframe</h5>
      <p>This wireframe should give user a glimpse of understanding about the horse and build a trust
        relationship
        from better side.</p>
      <p>Ideally owners of horses would fill here data of horses in regards to theit health status. Results of
        races would be stored here automatically and give user brief overview what particular has achieved so
        far.
      </p>
    </div>
    <div className="col-6 col-lg-5">
      <img src="../../assets/works/betonyourhorse-case-study/wireframes-horse-history.png" className="img-fluid rounded"
        alt="Wireframe of horse screen" />
    </div>
  </div>
</div>
</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">

  <h3 className="py-3">Usability Study</h3>
  <h5>Methodology</h5>
  <ul>
    <li>Unmoderated usability study</li>
    <li>Location: Online</li>
    <li>Length: Each session will last about 15 minutes, based on a list of prompts, with a SUS following the
      study</li>
    <li>Participants must be:</li>
    <ul>
      <li>Gaving experience with horse betting</li>
      <li>Be owner of smartphone</li>
    </ul>
  </ul>
  <h5>Results</h5>
  <p>Recorded results were generally summarized in 4 points:</p>
  <dl className="row">
    <dt className="col-sm-4 ">P0: Races search is missing</dt>
    <dd className="col">Add page where user may find various races</dd>
  </dl>
  <dl className="row">
    <dt className="col-sm-4 ">P0: Add different bets types</dt>
    <dd className="col">Various types of bets are missing like bet on lose or bet on arriving on exact position</dd>
  </dl>
  <dl className="row">
    <dt className="col-sm-4 ">P1: Show clashes results</dt>
    <dd className="col">It would be helpful to see results of particular horse in compare to all participants in the
      race - how many this horse won and have lost with each other racer</dd>
  </dl>
  <dl className="row">
    <dt className="col-sm-4 ">P1: Show value of bets</dt>
    <dd className="col">Add graphical representation of each bet</dd>
  </dl>
  <dl className="row">
    <dt className="col-sm-4 ">P2: Rebuild horse stats</dt>
    <dd className="col">Horse stats should be filtered by the distance of the race</dd>
  </dl>
  <dl className="row">
    <dt className="col-sm-4 ">P2: Imperial/metrical</dt>
    <dd className="col">Allow to switch metrical to imperial units</dd>
  </dl>
</div>
</div>
<div className="row justify-content-center">
<div className="col-md-8 pt-3">

  <h3>High Fidelity Prototypes</h3>
    <dl className="row mt-3 pt-2 border rounded">
    <dt className="col-7 my-2">Project with high fidelity mock-ups</dt>
    <dd className="col d-grid  justify-content-center"><a
        href="https://glanonim.com/assets/works/betonyourhorse-case-study/BetHorse-HiFi.xd" target="_blank" rel="noreferrer"><button
          type="button" className="btn btn-dark btn-block" rel="noreferrer">Download Xd file</button></a></dd>
  </dl>
</div>
</div>

<p className="mt-3">Overview of all high fidelity mock-ups:</p>
<div className="row">
<div className="col-12 bg-adobexd rounded border shadow-sm overflow-auto slowd-box text-center">
  <img src="../../assets/works/betonyourhorse-case-study/hifi-mockups.png" className="" alt="High fidelity mock-ups" />
</div>
</div>

<div className="row my-4 justify-content-center">
<div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm  mr-2">
  <div className="row py-3 px-2">
    <div className="col">
      <h5>Race mock-up</h5>
      <p>This screen shows short overview, list of possible bets and recommendation of other races.</p>
      <p>List of possible bets was heavily reworked based on feedback from user study.</p>
    </div>
    <div className="col-8 col-lg-5">
      <img src="../../assets/works/betonyourhorse-case-study/hifi-race.png" className="img-fluid rounded"
        alt="Mock-up of race screen" />
    </div>
  </div>
</div>
</div>
<div className="row my-4 justify-content-center">
<div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm  mr-2">
  <div className="row py-3 px-2">
    <div className="col">
      <h5>Horse mock-up</h5>
      <p>This mock-up start with introducion of the horse. Later health data, historical results, analytics and
        upcoming races are being displayed.</p>
      <p>Horse mock-up recieved different analytics part based on usability study. Now it allows for filtering
        depending on many critera, like distance of races.</p>
    </div>
    <div className="col-8 col-lg-5">
      <img src="../../assets/works/betonyourhorse-case-study/hifi-horse.png" className="img-fluid rounded"
        alt="Mock-up of horse screen" />
    </div>
  </div>
</div>
</div>
<div className="row my-4 justify-content-center">
<div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm mr-2">
  <div className="row py-3 px-2">
    <div className="col">
      <h5>Wallet</h5>
      <p>This screen start with overview. Then list of active bets, statistical data, analytics and transaction
        history are being displayed.</p>
      <p>In compare to wireframes few elements were modified. Text alignment and appearance were modified. View
        of
        analytics section was redesigned as it purpose was redefined.</p>
    </div>
    <div className="col-8 col-lg-5">
      <img src="../../assets/works/betonyourhorse-case-study/hifi-wallet.png" className="img-fluid rounded"
        alt="Mock-up of wallet screen" />
    </div>
  </div>
</div>
</div>


<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">

  <h3 className="pt-3">Further Studies</h3>
  <p>In next rounds of studies the testing group should include someone people easily getting addicted to make
    sure no addictive patterns are being used and addiciton prevention mechanism are being implemented.</p>
  <p>The currency exchnage and transactions must be inestigated with better care.</p>
</div>
</div>

<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">

  <h3>Accessibility Consideration</h3>
  <p>Based on usability studies and good practice following accessibility features were planned:</p>
  <ul>
    <li>Every image and step would get additional alt text to match planned path during complete user flow</li>
    <li>Team would add high contrast option. It would be available in settings</li>
    <li>Team would add dark mode option. It would be available to switch in settings</li>

  </ul>

</div>
</div>

<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">

  <h3>Lesson Learned</h3>
  <p>Trust perception is crucial element for successful adoption of new technology and application. This aspect
    is
    often omitted or not taken seriosuly what is major mistake.</p>
  <p>It is tough to distinguish issue hidden behind proposal of new idea. Very often when users see issue they
    prefer to provice new solution instead of admitting pain point.</p>
</div>
</div>

<div className="row justify-content-center">
<div className="col-md-8 justify-content-center py-3">

  <h3>Credits and acknowledgments</h3>
  <p>I would like to thank to Sandeep Gantait and Franziska Schmidt for awesome cooperation on this project.
    path.
  </p>
  <p>Big thank you to Benjamin Krumnow and Raphaela Butz for coaching and guidance.
  </p>

  <p>Media credits:</p>
  <ul>
    <li>Persona photo by <a href="https://unsplash.com/photos/QjCNk0TxGfc" target="_blank" rel="noreferrer">
        Sigmund</a> on Unsplash
    </li>
    <li>Persona photo by <a href="https://unsplash.com/photos/6anudmpILw4" target="_blank" rel="noreferrer">Foro Sushi</a> on
      Unsplash
    </li>
  </ul>
</div>
</div>

</>
)
}

export default BetHorse;