import React from 'react';  

const LoyalFoodie = () => {

    return (
    <>
    <div className="rounded bg-hero shadow-sm row p0 mb-4 overflow-hidden" >
      <div className="col-6 pt-5 px-5">
        <small className="text-muted mb-0">Case Study</small>
        <h1 className="display-4">LoyalFoodie</h1>
        <p className="lead mb-0">April 2021</p>

      </div>
    <div className="col-6 mt-3 position-relative hero "> 
          <img src="../../assets/works/loyalfoode-case-study/hero.png"
          className="" alt="Mockup showing one of application screens" /> 

      </div>
    </div>
    <div className="row justify-content-center">
      <p className="lead py-5 px-3 col-md-8  justify-content-center">
        This project was created as a "capstone" for <a
          href="https://www.coursera.org/professional-certificates/google-ux-design" className="px-1" target="_blank" rel="noreferrer">UX Design
          Certification</a>
        Program. The idea was to develop
        one, random idea over few weeks to learn all stages of application development process. My selected idea was to
        create a loyalty program for food truck customers. Content and format of this case study was required by the
        course.
      </p>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3 className="pb-3">
          Welcome to LoyalFoodie
          <small className="text-muted ml-2">Introduction</small>
        </h3>
        <p>My name is Krzysztof (most people call me Chris). I must admit that I am overwhelmed with loyality programs I
          am assigned to and I do not see major benefits coming from them. Their terms of use are quirky, not
          transparent and prizes are not fair.</p>
        <p>I love to eat and cook but sometimes I have no energy to do something on my own. I used to go to the
          restuarant but nowadays only home deliery is an option. I must admit that there is no application and
          restaurant that would encourage me to visit them by having valuable loyalty program. I decided to change it...
        </p>

      </div>
    </div>

    <div className="row justify-content-center py-4">
      <div className="col-md-8 justify-content-center py-3">
        <dl className="row">
          <dt className="col-sm-3 ">My role</dt>
          <dd className="col-sm-9">
            <p>I was responsible for UX reasearch, user study and design. There were no direct co-creators however I
              received a lot of support from participants of design reviews and usability studies.</p>
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Project timings</dt>
          <dd className="col-sm-9">March - April 2021</dd>
        </dl>
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>The Problem</h3>
        <p>There are no loyalty programs aimed for food trucks and choices for small business are limited due to high
          cost
          of entry as most systems requires dedicated POS.</p>

      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>The Goal</h3>
        <p>The goal is to create an application of loyalty program that would allow for quick onboarding and easy use.
          Application should be accessible for most users, especially those historically excluded.</p>
      </div>
    </div>



    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Personas</h3>
        <h4 className="text-center my-3">John</h4>
        <div className="row justify-content-center">
          <div className="col-sm-9 col-lg-6 mr-2">
            <dl className="row">
              <dt className="col-sm-4">Age</dt>
              <dd className="col">29</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Education</dt>
              <dd className="col">M.Sc. in Mech. Eng.</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Hometown</dt>
              <dd className="col">Cologne, Germany</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Family</dt>
              <dd className="col">Single, living alone</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Occupation</dt>
              <dd className="col">Engineer in big company</dd>
            </dl>
          </div>
          <div className="col col-lg-3">
            <img src="../../assets/works/loyalfoode-case-study/erik-lucatero-d2MSDujJl2g-unsplash.jpg"
              className="col px-1 py-1 img-thumbnail" alt="Persona of John" />

          </div>
        </div>
        <figure className="text-center">
          <blockquote className="blockquote my-5">
            <p>“I am 2 drawings from finishing awesome prototype. I need to go to gym today. Where I would have lunch
              today?” </p>
          </blockquote>
        </figure>
        <div className="row justify-content-center pb-5 border-bottom">
          <div className="col bg-white border px-3 py-3 rounded mr-2">
            <p>John is mechanical product developer in big company. He was born in Germany but studied in US, as his
              parents (mother from India, father from Germany) were working in US. He loves cuisine from India and
              Lebanon. He prefers vegetarian food. He is tech-savvy and fan of Android.</p>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
            <h4 className="text-success">Goals</h4>
            <ul>
              <li>Wants to be healthy and handsome</li>
              <li>Wants to establish a family</li>
              <li>Wants to create breakthrough and innovative designs</li>
            </ul>
            <h4 className="text-danger">Frustrations</h4>
            <ul>
              <li>Has no time or place to cook</li>
              <li>Is spending too much time and energy at work</li>
              <li>Is having too many unhealthy snacks</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h4 className="text-center my-3">Dorothea</h4>
        <div className="row justify-content-center">
          <div className="col-sm-9 col-lg-6 mr-2">
            <dl className="row">
              <dt className="col-sm-4">Age</dt>
              <dd className="col">42</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Education</dt>
              <dd className="col">B.Sc. in Accounting</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Hometown</dt>
              <dd className="col">Tokio, Japan</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Family</dt>
              <dd className="col">Married, 2 children</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Occupation</dt>
              <dd className="col">Freelancer</dd>
            </dl>
          </div>
          <div className="col col-lg-4">
            <img src="../../assets/works/loyalfoode-case-study/alexander-dummer-UH-xs-FizTk-unsplash.jpg"
              className="col px-1 py-1 img-thumbnail" alt="Persona of Dorothea" />

          </div>
        </div>
        <figure className="text-center">
          <blockquote className="blockquote my-5">
            <p>“I wish my day would have 48 hours. Let’s do some cake and later check the homework. I wish I could save
              some time on cooking dinner...“</p>
          </blockquote>
        </figure>
        <div className="row justify-content-center pb-5 border-bottom">
          <div className="col bg-white border px-3 py-3 rounded mr-2">
            <p>Dorothea moved from Japan to study in Germany when she was 21. Three years later she met her husband,
              Marcus. Since she is 25 she is working as freelancer, as copywriter. She loves spending time with her
              daughter, Lisa and son, Thomas. She loves simplicity of Apple devices.</p>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
            <h4 className="text-success">Goals</h4>
            <ul>
              <li>Wants to raise children as good and smart citizens</li>
              <li>Wants to be creative and earn few quids along</li>
              <li>Wants to be good mother and wife</li>
            </ul>
            <h4 className="text-danger">Frustrations</h4>
            <ul>
              <li>Not enough time</li>
              <li>No time to look for opportunities, special offers and deals</li>
              <li>Overwhelmed with responsibilities</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Competitors Analysis</h3>
        <p>After brief competitors 5 different direct competitors were named:</p>
        <table className="table table-borderless text-center">
          <tbody>
            <tr className="row d-flex align-items-end">
              <td className="py-3 col-4"><img src="../../assets/works/loyalfoode-case-study/House-Badge-RGB.svg"
                  className="img-fluid col-6" alt="takeaway.com logo" />
                <p className="mt-2">Food delivery apps</p>
              </td>
              <td className="py-3 col-4"><img
                  src="../../assets/works/loyalfoode-case-study/fb640d369339e3ac362b1865a799d5656b8acb7f.png"
                  className="img-fluid" alt="Lightspeed logo" />
                <p className="mt-2">Lightspeed/Upserve</p>
              </td>
              <td className="py-3 col-4"><img src="../../assets/works/loyalfoode-case-study/sailplay.svg"
                  className="img-fluid" alt="Sailplay logo" />
                <p className="mt-2">Sailplay</p>
              </td>
            </tr>
            <tr className="row d-flex align-items-end justify-content-center">
              <td className="py-3 col-4 "><img
                  src="../../assets/works/loyalfoode-case-study/loyalzoo-logo-colour-svg.svg" className="img-fluid col-10"
                  alt="LoyalZoo logo" />
                <p className="mt-2">LoyalZoo</p>
              </td>
              <td className="py-3 col-4"><img src="../../assets/works/loyalfoode-case-study/Fivestars_Logo.png"
                  className="img-fluid" alt="FiveStars logo" />
                <p className="mt-2">FiveStars</p>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <p>First one is collection of all food delivery application that are allowing users to collect points and
          exchange them into goods, as logo Takeaway.com was taken.</p>
        <p>Lightspeed/Upserve, LoyalZoo and FiveStars are POS vendors where FiveStars is building offering only around
          custom POS device. Sailplay is being position as markting tool. Lightspeed has wide variety of products where
          customers loyalty program is tiny part of their portfolio.</p>
        <p>Analysis helped to find out about differences in authenticating and user recognition. Sometimes it is phone
          number or mail given verbally. It may be smartphone or credit card.</p>
        <p>After some time it was obvious that platforms are having wrong focus - they are aiming on reaching and
          coining business owner rather than pleasing this business customers.</p>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Research</h3>
        <p>I conducted interviews with target users, then distilled what I learned into actionable steps. I used all
          discovered insights to identify pain points users were experiencing. Then I entered research with set of
          assumptions that were modified based on future users feedback.</p>
        <p>The most important discovered pain points:</p>
        <ul>
          <li>Users needs easy and quick way to check-in in the restaurant</li>
          <li>Rules must be basic</li>
          <li>Check out must be automatic</li>
          <li>Offers must be appealing</li>
        </ul>
        <p>Unvcovered information helped me to realize I need to do more to make navigation easy and limit steps user
          must take to complete flow. Than conclusion helped to shape next steps.</p>
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">

        <h3>Problems Statements</h3>
        <p>By applying the user pain points I identified in LoyalFoodie study, the problem statements for John and
          Dorothea would be:</p>
        <p><u className="text-primary">John</u> is a <u className="text-danger">foodie</u> who needs <u className="text-success">a
            additional offers</u> because <u className="text-warning">he does not what restaurant to decide on</u>.</p>
        <p><u className="text-primary">John</u> is a <u className="text-danger">busy working professional</u> who needs <u
            className="text-success">clear and basic rules</u> becuase <u className="text-warning">he has not time to read
            terms</u>.</p>
        <p><u className="text-primary">Dorothea</u> is a <u className="text-danger">busy mother</u> who needs <u
            className="text-success">easy navigation</u> because <u className="text-warning">she has no time to research user
            guide</u>.</p>
        <p><u className="text-primary">Dorothea</u> is a <u className="text-danger">good cook</u> who needs <u
            className="text-success">catchy offer</u> becuase <u className="text-warning">she can cook well at home</u>.</p>
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 pt-3">

        <h3>Initial Wireframes</h3>
      </div>
    </div>
    <p className="mt-3">Overview of all wireframes created in Figma:</p>
    <div className="row py-0 px-0 "><iframe width="100%" height="450" title="Overview of all wireframes created in Figma" className="mx-0 my-0 px-0 py-0 shadow-sm rounded border"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fvz4V70lmSC6mU3RQXHrYQx%2FLoyalFoodie%3Fnode-id%3D0%253A1"
        allowFullScreen></iframe>
    </div>

    <div className="row my-4 justify-content-center">
      <div className="col bg-secondary rounded border text-white shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <p>Starting page would allow directly to check in first at the restaurant to see available offer and
              prizes. There are 2 methods of check in foreseen - by scanning QR-code available in the restaurant or
              by bluetooth beacon (BLE).</p>
            <p>User may log in, log in with 3rd party authentication provider or sign up.</p>
          </div>
          <div className="col-12 col-lg-6">
            <img src="../../assets/works/loyalfoode-case-study/wireframes-starting-page.png" className="img-fluid rounded"
              alt="Wireframe of Starting screen" />
          </div>
        </div>
      </div>
      <div className="col bg-secondary rounded border text-white shadow-sm">
        <div className="row py-3 px-2">
          <div className="col">
            <p>Offers page would show how many points user has. It would allow user to quickly compare his situation
              with benefits coming from using one or multiple offers.</p>
            <p>After clicking one of offers a separate screen with QR-code and detailed description would appear. It
              is foreseen to show this code to the seller so it may be added to the order.</p>
          </div>
          <div className="col-12 col-lg-6">
            <img src="../../assets/works/loyalfoode-case-study/wireframes-offers-page.png" className="img-fluid rounded"
              alt="Wireframe of Offers screen" />
          </div>
        </div>
      </div>

    </div>


    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">

        <h3 className="py-3">Usability Study</h3>
        <h5>Methodology</h5>
        <ul>
          <li>Unmoderated usability study</li>
          <li>Location: Germany, in selected restaurant</li>
          <li>Length: Each session will last about 15 minutes, based on a list of prompts, with a SUS following the
            study</li>
          <li>Participants must be:</li>
          <ul>
            <li>Foodies (live to eat and enjoy eating various food types)</li>
            <li>Be between 18 and 35</li>
            <li>At least 3 male and 3 female</li>
            <li>Have a car or live in the city center</li>
            <li>At least 2 of them should not speak German</li>
          </ul>
        </ul>
        <h5>Results</h5>
        <p>Recorded results were generally summarized in 4 points:</p>
        <dl className="row">
          <dt className="col-sm-4 ">Confused how to start</dt>
          <dd className="col">As log-in, sign-up and check-in are on landing page users were confused what to do</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">Simplify log-in</dt>
          <dd className="col">Users hate new passwords and would appreciate when log-in would be easy</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">Could not work offline</dt>
          <dd className="col">Users need to be able to use application without direct access to the Internet</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">Points are shady</dt>
          <dd className="col">Users would need to be more clear on what they are getting for “those” points</dd>
        </dl>
        <h5>Findings and rationale</h5>
        <p>First summary had following working points:</p>
        <ul>
          <li>Allow only to log-in with Google on Android devices</li>
          <li>Do not allow to check-in until log-in completed</li>
          <li>Add automatic check-in process</li>
          <li>Highlight how point are collected (rules)</li>
          <li>Add offline capability</li>
        </ul>
        <p>After consideration it was decided that standard login method would stay in. It would be shame to reject
          users who do not have Google account.</p>
        <p>The key change was modification of navigation: as of now user must first log in to be able to check in.
          Moreover
          navigation was simplified and remains at the bottom of the screen.</p>
        <p>To make points collection process more transparent on the offer page would be added collection rate. Offline
          check-in capability would be added, however it would delay the update of points counter, awaiting the user to
          be in range of the Internet.</p>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 pt-3">

        <h3>High Fidelity Prototypes</h3> 
        <dl className="row pt-2 border rounded">
          <dt className="col-sm-7 pt-2">Project with high fidelity mock-ups</dt>
          <dd className="col"><a href="https://www.figma.com/file/vz4V70lmSC6mU3RQXHrYQx/LoyalFoodie?node-id=60%3A4"
              target="_blank" rel="noreferrer"><button type="button" className="btn btn-dark btn-block">Open in Figma</button></a></dd>
        </dl>
        <dl className="row pt-2 border rounded">
          <dt className="col-sm-7 pt-2">High fidelity prototype with animated user flow</dt>
          <dd className="col"><a
              href="https://www.figma.com/proto/vz4V70lmSC6mU3RQXHrYQx/LoyalFoodie?node-id=60%3A4&scaling=scale-down&page-id=60%3A2"
              target="_blank" rel="noreferrer"><button type="button" className="btn btn-dark btn-block">Open in Figma</button></a></dd>
        </dl>
      </div>
    </div>

    <p className="mt-3">Overview of all high fidelity mock-ups:</p>
    <div className="row py-0 px-0 "><iframe width="100%" height="450" title="Overview of all high fidelity mock-ups created in Figma" className="shadow-sm mx-0 my-0 px-0 py-0 rounded border"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fvz4V70lmSC6mU3RQXHrYQx%2FLoyalFoodie%3Fnode-id%3D60%253A2"
        allowFullScreen></iframe>
    </div>

    <div className="row my-4 justify-content-center">
      <div className="col bg-secondary rounded border text-white shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <p>Offers page would now indicate where the user is checked in. It got button to check out, conisdered handy
              when automatic check in went wrong.</p>
            <p>Points collection rate is now highlighted above all offers.</p>
          </div>
          <div className="col-12 col-lg-6">
            <img src="../../assets/works/loyalfoode-case-study/high-fidelity-mockups-offers.png" className="img-fluid rounded"
              alt="Wireframe of Starting screen" />
          </div>
        </div>
      </div>
      <div className="col bg-secondary rounded border text-white shadow-sm">
        <div className="row py-3 px-2">
          <div className="col">
            <p>Check in page is including now list of all restaurants (BLE beacons) available around.</p>
          </div>
          <div className="col-12 col-lg-6">
            <img src="../../assets/works/loyalfoode-case-study/high-fidelity-mockups-checkin.png" className="img-fluid rounded"
              alt="Wireframe of Offers screen" />
          </div>
        </div>
      </div>
      </div>


      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3 className="pt-3">Further Studies</h3>
          <p>In final round of studies it was discovered that users would like to have better understanding of points
            flow. It should be considered to add separate tab or screen showing transactions history.</p>
          <p>Studies conducted so far were not taking into account seller perspective. Application for restaurant must
            be developed and tested in order to consider this study as complete.</p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Accessibility Consideration</h3>
          <p>Based on usability studies and good practice following accessibility features were planned:</p>
          <ul>
            <li>Every image and step would get additional alt text to match planned path during complete user flow</li>
            <li>Team would add high contrast option. It would be available in settings</li>
            <li>Team would add dark mode option. It would be available to switch in settings</li>

          </ul>

        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Lesson Learned</h3>
          <p>Iteration is the key to successful and wise design. Humans affection cannot identify, analyse and fix too
            many problems at once. Every iteration of LoyalFoodie brought conclusions that were at that point of time
            obvious.</p>
          <p>Keep It Stupid Simple is principle that rules navigation. At first sight it may seems that users are
            confused, not fluent with smartphone or even lazy but all of those are wrong assumptions - the user is the
            king. If user suggest it is too complicated. Damn! It must be simplified as soon as it is possible. </p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Credits and acknowledgments</h3>
          <p>I would like to thank to Michael, Emily, Karen, Jason, Dave, Paolo, Shabi and Kunal from Google who created
            <a href="https://www.coursera.org/professional-certificates/google-ux-design" className="mx-1" target="_blank" rel="noreferrer">UX Design
              Certification</a>
            path.
          </p>
          <p>Media credits:</p>
          <ul>
            <li>Persona photo by <a
                href="https://unsplash.com/@erik_lucatero?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Erik
                Lucatero</a> on <a
                href="https://unsplash.com/s/photos/men?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Unsplash</a>
            </li>
            <li>Persona photo by <a
                href="https://unsplash.com/@4dgraphic?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Alexander
                Dummer</a> on <a
                href="https://unsplash.com/s/photos/mother?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Unsplash</a>
            </li>

            <li>Food icons by <a href="https://www.freepik.com" title="Freepik" target="_blank" rel="noreferrer">Freepik</a> from <a
                href="https://www.flaticon.com/" title="Flaticon" target="_blank" rel="noreferrer">www.flaticon.com</a></li>
         <li>Hero image based on mock-up from FreeMockupZone</li>
              </ul>
        </div>
      </div>

</>
)
}

export default LoyalFoodie;