import React from 'react';  

const MentorMe = () => {

    return (
    <>
   <div className="rounded bg-hero shadow-sm row p0 mb-4 overflow-hidden" >
      <div className="col-6 pt-5 px-5">
        <small className="text-muted mb-0">Case Study</small>
        <h1 className="display-4">MentorMe</h1>
        <p className="lead mb-0">April 2021</p>
      </div>
    <div className="col-6 mt-3 position-relative hero "> 
        <img src="../../assets/works/mentorme-case-study/hero.png"
          className="" alt="Mockup showing one of application screens" /> 

      </div>
    </div>  
 
    <div className="row justify-content-center">
      <p className="lead py-5 px-3 col-md-8  justify-content-center">
        This project was created as the second "capstone" for <a
          href="https://www.coursera.org/professional-certificates/google-ux-design" className="mx-1" target="_blank" rel="noreferrer">UX Design
          Certification</a>
        Program, focusing on the use of Adobe XD. The idea was to develop
        one, randomly generated idea over few weeks to learn all stages of application development process. My selected
        idea was to
        create an application for online mentoring app at university. Content and format of this case study was
        influenced by the
        course.
      </p>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3 className="pb-3">
          Welcome to MentorMe
          <small className="text-muted ml-2">Introduction</small>
        </h3>
        <p>My name is Krzysztof and I had an opportunity to study B.Sc. and M.Sc. programs. I must admit that beginnings
          were
          always difficult. I had no idea what to do, when I can party and what topic for the thesis would be valuable.
          Idea of mentorship could be very helpful in mentioned matters and many more.</p>

      </div>
    </div>

    <div className="row justify-content-center py-4">
      <div className="col-md-8 justify-content-center py-3">
        <dl className="row">
          <dt className="col-sm-3 ">My role</dt>
          <dd className="col-sm-9">
            <p>I was responsible for UX reasearch, user study and design. There were no direct co-creators however I
              received a lot of support from participants of design reviews and usability studies.</p>
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Project timings</dt>
          <dd className="col-sm-9">April 2021</dd>
        </dl>
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Empathy map</h3>
        <p>Below one of two maps created. Empathy maps were created during initial interviews with M.Sc. students from
          various programs and universities.</p>
        <div className="position-relative">
          <div
            className="position-absolute name-emmap top-50 start-50 translate-middle border bg-white shadow-sm p-3 rounded-circle d-flex align-items-center justify-content-center">
            <h4>Paulina</h4>
          </div>

          <div className="row justify-content-center">
            <div className="col name-emmap-puzzle border rounded p-4 bg-white shadow-sm mr-2">
              <h4>Says</h4>
              <p>“I am super-excited to be accepted at this university. But can I manage to finish my program?”</p>
            </div>
            <div className="col name-emmap-puzzle border rounded p-4 bg-white shadow-sm text-end">
              <h4>Thinks</h4>
              <p>Excitement in voice</p>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col name-emmap-puzzle border rounded p-4 bg-white shadow-sm mr-2">
              <h4>Does</h4>
              <p>Talks to her roommate and other people from the neighborhood</p>
            </div>
            <div className="col name-emmap-puzzle border rounded p-4 bg-white shadow-sm text-end">
              <h4>Feels</h4>
              <p>Anxious as she just changed her life.
                Proud she managed to get accepted.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row justify-content-center mt-3">
      <div className="col-md-8 justify-content-center py-3">
        <h3>The Problem</h3>
        <p>Many universities do not have any mentorship program. Those who invested into such initiative are keeping it
          too simple and at the end there are many students with bad experience and no mentor at all. During initial
          interviews the requesting process was accounted as root cause. You add your photo, basic information,
          description and wait. You may wait forever...</p>

      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>The Goal</h3>
        <p>The goal was to create an application for mentoring for online university with inlusive and delightful
          request process.</p>
      </div>
    </div>



    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Personas</h3>
        <h4 className="text-center my-3">Stephanie</h4>
        <div className="row justify-content-center">
          <div className="col-sm-9 col-lg-6 mr-2">
            <dl className="row">
              <dt className="col-sm-4">Age</dt>
              <dd className="col">18</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Education</dt>
              <dd className="col">High School diploma</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Hometown</dt>
              <dd className="col">Marion, OH</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Family</dt>
              <dd className="col">Parents, 2 sisters, single</dd>
            </dl>
          </div>
          <div className="col col-lg-4">
            <img src="../../assets/works/mentorme-case-study/christopher-campbell-rDEOVtE7vOs-unsplash.jpg"
              className="col px-1 py-1 img-thumbnail" alt="Persona of Stephanie" />  
        </div>
          </div>
        <figure className="text-center">
          <blockquote className="blockquote my-5">
            <p>“I love being with people and learning new stuff” </p>
          </blockquote>
        </figure>
        <div className="row justify-content-center pb-5 border-bottom">
          <div className="col bg-white border px-3 py-3 rounded mr-2">
            <p>Stephanie just got accepted at Ohio State University and she moved to a dormitory in Columbus, Ohio. She
              will study online medicine as she pursuing career path to become a doctor. In a free moments she likes
              reading, Netflix and taking care of dogs in animal shelters.</p>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
            <h4 className="text-success">Goals</h4>
            <ul>
              <li>Finish studies</li>
              <li>Become a doctor</li>
              <li>Help others</li>
              <li>Be responsible</li>
              <li>Find love of her life</li>
            </ul>
            <h4 className="text-danger">Frustrations</h4>
            <ul>
              <li>No clue how to start studies well</li>
              <li>Lost in translation</li>
              <li>A bit lonely</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h4 className="text-center my-3">Mark</h4>
        <div className="row justify-content-center">
          <div className="col-sm-9 col-lg-6 mr-2">
            <dl className="row">
              <dt className="col-sm-4">Age</dt>
              <dd className="col">23</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Education</dt>
              <dd className="col">B.Sc. and starting M.Sc.</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Hometown</dt>
              <dd className="col">Monroe, IN</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Family</dt>
              <dd className="col">Mother, single</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Occupation</dt>
              <dd className="col">Practicant</dd>
            </dl>
          </div>
          <div className="col col-lg-4">
            <img src="../../assets/works/mentorme-case-study/brooke-cagle-Nm70URdtf3c-unsplash.jpg"
              className="col px-1 py-1 img-thumbnail" alt="Persona of Mark" />

          </div>
        </div>
        <figure className="text-center">
          <blockquote className="blockquote my-5">
            <p>“Today is going to be a good day“</p>
          </blockquote>
        </figure>
        <div className="row justify-content-center pb-5 border-bottom">
          <div className="col bg-white border px-3 py-3 rounded mr-2">
            <p>Mark recently finished Biochemistry at Ohio State University and he wants to continue his education in
              this field of study. He would like to start now part time job but still to enjoy student life. In his free
              time Mark is playing PlayStation and painting graffitis.</p>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
            <h4 className="text-success">Goals</h4>
            <ul>
              <li>Be a good person</li>
              <li>Become a scientist</li>
              <li>Have a good life</li>
              <li>Do something that matters</li>
              <li>Feel accomplished (get good grades)</li>
            </ul>
            <h4 className="text-danger">Frustrations</h4>
            <ul>
              <li>Sad to see so many people failing during studies</li>
              <li>Studies are too damn expensive</li>
              <li>Due to Covid-19 missing energy of others</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>User Story</h3>
        <p>Based on persona excercise two User Stories were written:</p>
        <figure>
          <blockquote className="blockquote">
            <p>As a brave but inexperienced student I want to have my mentee profile pre-filled so that I can focus on
              other important aspects
            </p>
          </blockquote>
          <figcaption className="blockquote-footer">
            Stephanie
          </figcaption>
        </figure>
        <figure>
          <blockquote className="blockquote">
            <p>As a experienced student and future mentor I want to get recommended mentee based on my profile so that I
              can save some time on pre-selecting them
            </p>
          </blockquote>
          <figcaption className="blockquote-footer">
            Mark
          </figcaption>
        </figure>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Problem Statement</h3>
        <p>Persona, User Stories and User Journey Maps allowed to form initial Problem Statements:</p>
        <p><u className="text-primary">Mark</u> is a <u className="text-danger">busy mentor</u> who needs <u
            className="text-success">a
            mentee recommendation system</u> because <u className="text-warning">pre-selecting candidates takes too much
            time</u>.</p>
        <p><u className="text-primary">Stephanie</u> is a <u className="text-danger">brave mentee</u> who needs <u
            className="text-success">pre-filled profile based on Social Media and university account
          </u> because <u className="text-warning">providing basic information decreases her energy level</u>.</p>

      </div>
    </div>




    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">

        <h3>Competetive Analysis</h3>
        During analysis 11 competitors were found:
        <div className="competitors">
          <div className="row mt-3">
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/974b428b-a9f1-4042-ad29-695ef8af3094.png" className="img-fluid col-7"
                alt="Logo of MentorcliQ" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/c54841a0-50cc-4895-83ba-ce28e2eb75e0.png" className="img-fluid col-7"
                alt="Logo of PushFar" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-2 text-center"><img
                src="../../assets/works/mentorme-case-study/fa647958-9f6b-4288-b6aa-fa7ed21f8776.png" className="img-fluid col-9"
                alt="Logo of Wisdom Share" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/fb9d7492-9d97-4a4f-aa36-7e8eb1849b4f.png" className="img-fluid col-7"
                alt="Logo of Mentor City" /></div>
          </div>
          <div className="row mt-3">
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/40479fc7-fc67-46fb-bf8e-c963085445a8.png" className="img-fluid col-7"
                alt="Logo of Mentornity" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/8e04bf93-c860-4eab-8f42-46d7374e699c.png" className="img-fluid col-7"
                alt="Logo of MentoringComplete" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/bc92366f-270f-402e-90c3-719c475e9fc7.png" className="img-fluid col-7"
                alt="Logo of GraduWay" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/c895d46a-1e8e-48e2-bd36-7325306f9402.png" className="img-fluid col-7"
                alt="Logo of FirstHand" /></div>
          </div>
          <div className="row mt-3">
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/LI-Bugsvgoriginal.svg" className="img-fluid col-5"
                alt="Logo of LinkedIn" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/XNG_Sharebutton_v01-7d06f36109c803c7a79f5a5c597f1fb8c8ff4d310d74ee8f6b31a56b6d6c3eea.png"
                className="img-fluid col-4" alt="Logo of Xing" /></div>
            <div className="col mx-1 bg-white rounded border shadow-sm p-3 text-center"><img
                src="../../assets/works/mentorme-case-study/mentorlane.png" className="img-fluid col-5"
                alt="Logo of MentorLane" /></div>
          </div>

        </div>
        <p className="mt-4">Based on their target groups they may be divided into 3 buckets:</p>
        <ul>
          <li>Direct competition: Mentoring systems for university students</li>
          <li>Indirect competition: Mentoring systems for companies</li>
          <li>Indirect competition: Social media with mentoring feature</li>
          <li>Indirect competition: Mentoring systems for private professionals</li>
        </ul>

        <p className="mt-4">Conclusions from the analysis:</p>
        <ul>
          <li>There are 2 types of mentoring: flash and structured</li>
          <li>Competitors are focusing on drawing a target for mentoring and phrasing clear need</li>
          <li>Many competitors is having job offers as base or feature</li>
          <li>Commonly used phrases are achieve, help, boost and target</li>
        </ul>
      </div>
    </div>



    <div className="row justify-content-center">
      <div className="col-md-8 pt-3">

        <h3>Initial Wireframes</h3>
      </div>
    </div>
    <p className="mt-3">Overview of wireframes created in Adobe XD:</p>
    <div className="row">
      <div className="col-12 bg-adobexd rounded border shadow-sm overflow-auto slowd-box">
        <img src="../../assets/works/mentorme-case-study/wireframes.png" className="" alt="Wireframes" />
      </div>
    </div>

    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-10 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Meetings Wireframe</h5>
            <p>Meetings page is intended to be the center part of daily operation. It should be the place where use would schedule new meetings, edit existing ones and preview his calendar. Calendar should be switchable between week, month or daily view with focus on user active hours.</p>
            <p>It was planned to show latest acitivites of users in selected, connected social media. That would allow for more engaging discussion, especially small talk at the beginning.</p>
          </div>
          <div className="col-12 col-lg-8">
            <img src="../../assets/works/mentorme-case-study/Meetings_web.png" className="img-fluid rounded"
              alt="Wireframe of Meetings screen" />
          </div>
        </div>
      </div> 
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-10 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Settings Wireframe</h5>
            <p>Settings screen would allow to import data from university profile as well as from social media.</p>
            <p>Accessibility features like high contrast mode would be accessible from here.</p>
          </div>
          <div className="col-12 col-lg-8">
            <img src="../../assets/works/mentorme-case-study/Settings_web.png" className="img-fluid rounded"
              alt="Wireframe of Settings screen" />
          </div>
        </div>
      </div> 
    </div>


    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">

        <h3 className="py-3">Usability Study</h3>
        <h5>Methodology</h5>
        <ul>
          <li>Unmoderated usability study</li>
          <li>Location: Online</li>
          <li>Length: Each session will last about 15 minutes, based on a list of prompts, with a SUS following the
            study</li>
          <li>Participants must be:</li>
          <ul>
            <li>Student</li>
            <li>Be between 18 and 35</li>
            <li>At least 2 male and 2 female</li> 
          </ul>
        </ul>
        <h5>Results</h5>
        <p>Recorded results were generally summarized in 4 points:</p>
        <dl className="row">
          <dt className="col-sm-4 ">Social Media not needed</dt>
          <dd className="col">Most users found activity stream from social media intimidating and not needed</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">Differntiate better</dt>
          <dd className="col">Add indication if user is searching for mentee or mentor</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">Do not show reviews</dt>
          <dd className="col">Showing reviews about user would make more bounded due to the nature of contact</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">Favourites</dt>
          <dd className="col">Add possibility to mark person even if there was no meeting so far.</dd>
        </dl>
        <h5>Implementation</h5> 
        <p>After consideration it was to rebuild start page, to show there profiles of users. Preview of reviews would be removed. Clear indication on search type (mentor/mentee) will be added</p>
        <p>Social media integartion will be kept but only to import initial profile data based on followed pages and posts.</p> 
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 pt-3">

        <h3>High Fidelity Prototypes</h3>
  <dl className="row mt-3 pt-2 border rounded">
          <dt className="col-sm-7 pt-2">PDF with high fidelity mock-ups</dt>
          <dd className="col d-grid justify-content-center"><a href="assets/works/mentorme-case-study/MentorMe-MockupsHiFi.pdf"
              target="_blank" rel="noreferrer"><button type="button" className="btn btn-dark btn-block">Open .pdf</button></a></dd>
        </dl> 
      </div>
    </div>

    <p className="mt-3">Overview of mock-ups created in Adobe XD:</p>
    <div className="row">
      <div className="col-12 bg-adobexd rounded border shadow-sm overflow-auto slowd-box">
        <img src="../../assets/works/mentorme-case-study/mockups.png" alt="Mock-ups" />
      </div>
    </div>

    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-10 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Meetings Mock-up</h5>
            <p>Based on feedback from usability studies a availability rules were added to this screen. With this feature, the user may show to requesting people, when he may be available.</p>
            <p>Social media feature was removed as users found it confusing.</p>
          </div>
          <div className="col-12 col-lg-8">
            <img src="../../assets/works/mentorme-case-study/HiFi-Meetings-Web.jpg" className="img-fluid rounded"
              alt="Mockups of Meetings screen"/>
          </div>
        </div>
      </div> 
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-10 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Profile Mock-up</h5>
            <p>Profile remained relateively unchanged comparing to wireframes. Reviews preview was moved below the profile photo. Based on users feedback there is no more preview of the review.</p>
            <p>Experience section was redesigned to show at glance the complete professional history of the user.</p>
          </div>
          <div className="col-12 col-lg-8">
            <img src="../../assets/works/mentorme-case-study/HiFi-Profile-Web.jpg" className="img-fluid rounded"
              alt="Mockups of Profile screen"/>
          </div>
        </div>
      </div> 
    </div>
    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-10 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Search Mock-up</h5>
            <p>Search page was revised in terms of search results, they are bigger, with more description. </p>
            <p>At the top a switch was added to indicate if user is looking for mentor or mentee.</p>
          </div>
          <div className="col-12 col-lg-8">
            <img src="../../assets/works/mentorme-case-study/HiFi-Search-Web.jpg" className="img-fluid rounded"
              alt="Mockups of Search screen"/>
          </div>
        </div>
      </div> 
    </div>
    


      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3 className="pt-3">Further Studies</h3>
          <p>In the next round of usability studies it was found that calendar should be redesigned as users found it unclear and inconvinient to use.</p>
          <p>Further studies should show pieces on interaction happening via mail messages, like accepting invitation or review request.</p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Accessibility Consideration</h3>
          <p>Based on usability studies and good practice following accessibility features were planned:</p>
          <ul>
            <li>Read aloud option that would improve page design for screen readers</li>
            <li>Enabling dark mode and high contrast mode in CSS</li>
            <li>All media and objects would have proper description</li>

          </ul>

        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Lesson Learned</h3>
          <p>It is difficult to figure out actionable feedback without plenty of useless initial interation. I would rather have more parallel approaches to same problem to select most promising after initial studies, like this one.</p>
          <p>I learned how to better organize my design and how to utilize many Adobe Xd features to increase productivity. </p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Credits and acknowledgments</h3>
          <p>I would like to thank to Michael, Emily, Karen, Jason, Dave, Paolo, Shabi and Kunal from Google who created
            <a href="https://www.coursera.org/professional-certificates/google-ux-design" className="mx-1" target="_blank" rel="noreferrer">UX Design
              Certification</a>
            path.
          </p>
          <p>Media credits:</p>
          <ul>
            <li>Persona photo by <a
                href="https://unsplash.com/@chrisjoelcampbell?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Christopher Campbell</a> on <a
                href="https://unsplash.com/s/photos/woman-face?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Unsplash</a>

            </li>
            <li>Persona photo by <a
                href="https://unsplash.com/@brookecagle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Brooke Cagle</a> on <a
                href="https://unsplash.com/s/photos/men?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank" rel="noreferrer">Unsplash</a>
            </li>
            <li>Photo by <a href="https://www.pexels.com/photo/woman-smiling-and-holding-teal-book-1181424/"
                target="_blank" rel="noreferrer">Christina Morillo</a> from Pexels</li>
            <li>Photo by <a href="https://www.pexels.com/photo/woman-standing-near-whiteboard-1181519/"
                target="_blank" rel="noreferrer">Christina Morillo</a> from Pexels</li>
            <li>Photo by <a href="https://www.pexels.com/photo/woman-sitting-and-smiling-1858175/"
                target="_blank" rel="noreferrer">Guilherme Almeida</a> from Pexels</li>
            <li>Photo by <a href="https://www.pexels.com/photo/woman-wearing-eyeglasses-773371/" target="_blank" rel="noreferrer">Tarzine
                Jackson</a> from Pexels</li>
            <li>Photo by <a href="https://www.pexels.com/photo/portrait-of-an-old-man-wearing-brown-fedora-hat-3863793/"
                target="_blank" rel="noreferrer">Kevin Bidwell</a> from Pexels</li>
            <li>Photo by <a
                href="https://www.pexels.com/photo/man-wearing-black-zip-up-hooded-jacket-facing-camera-1080213/"
                target="_blank" rel="noreferrer">João Jesus</a> from Pexels</li>
            <li>Photo by <a href="https://www.pexels.com/photo/man-people-woman-girl-6147369/" target="_blank" rel="noreferrer">Keira
                Burton</a> from Pexels</li>
            <li>
              Icons made by <a href="https://www.flaticon.com/authors/darius-dan" title="Darius Dan">Darius
                  Dan</a>, <a href="https://www.flaticon.com/authors/mynamepong" title="mynamepong">mynamepong</a>, <a
                  href="https://www.flaticon.com/authors/vitaly-gorbachev" title="Vitaly Gorbachev">Vitaly
                  Gorbachev</a>, <a href="https://www.freepik.com" title="Freepik">Freepik</a> and <a
                  href="https://www.flaticon.com/authors/dinosoftlabs" title="DinosoftLabs">DinosoftLabs</a> from <a
                  href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </li>
          </ul>
        </div>
      </div>

</>
)
}

export default MentorMe;