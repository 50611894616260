import React from 'react';
import ItemsList from './ItemsList';
import { Link } from "react-router-dom";

const ShowWorks = ({showLatest}) => ( 
<>
{showLatest ? ( ItemsList.slice(ItemsList.length-2, ItemsList.length).map((article,key)=> (
  <div className="card border-0 col-8 col-md-5 mx-1 my-1 px-0 shadow-sm rounded">
    <div className="card-img-top bg-light rounded-top hero-works text-center overflow-hidden "> 
    <img src={ window.location.origin + "/assets/" + article.image } className="mt-3" alt="Hero graphic of this project" /> 
    </div>
    <div className="card-body px-3 bg-white rounded-bottom">
      <h5 className="card-title">{article.title}</h5>
      <p className="card-text">{article.description.substring(0,250)}...</p> 
      <Link key={key} to={`/case-study/${article.address}`} >
          <button className="btn btn-secondary">Go there</button>
      </Link>
    </div>
  </div>
)) ) : ( ItemsList.map((article,key)=> (
  <div className="card border-0 col-8 col-md-5 mx-1 my-1 px-0 shadow-sm rounded">
    <div className="card-img-top bg-light rounded-top hero-works text-center overflow-hidden "> 
    <img src={ window.location.origin + "/assets/" + article.image } className="mt-3" alt="Hero graphic of this project" /> 
    </div>
    <div className="card-body px-3 bg-white rounded-bottom">
      <h5 className="card-title">{article.title}</h5>
      <p className="card-text">{article.description.substring(0,250)}...</p> 
      <Link key={key} to={`/case-study/${article.address}`} >
          <button className="btn btn-secondary">Go there</button>
      </Link>
    </div>
  </div>
)))
}

</>
);
 
export default ShowWorks;