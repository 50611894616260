import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import ItemsList from "../components/ItemsList";
import RenderSwtich from "../components/RenderSwitch";
import ShowOthers from "../components/ShowOthers";

const CaseStudy = ({ match }) => {
  const title = match.params.title;
  const article = ItemsList.find((article) => article.address === title);

  return (
    <>
      <nav className="container">
        <Breadcrumbs className="my-4" aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <Link to="/all-works">All works</Link>
          <Typography color="textPrimary" aria-current="page">
            {" "}
            {article.title}{" "}
          </Typography>
        </Breadcrumbs>
      </nav>
      <main className="container pb-5">
        <RenderSwtich title={title} />

        <div className="row py-4">
          <h4 className="text-center col-12 py-3">Continue reading</h4>
          <div className="col-12">
            <div className="row pb-5 justify-content-center">
              <ShowOthers title={title} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CaseStudy;
