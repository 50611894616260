 
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LandingPage from './LandingPage';
import AllWorks from './AllWorks';
import CaseStudy from './CaseStudy';
import P404 from './P404'; 
import TopBar from '../components/TopBar'; 

function App() {
  return (

  <main> 
      <Router>
        <TopBar/>
        <Switch> 
          <Route path="/" exact>
            <LandingPage />
          </Route>  
          <Route path="/landing-page">
            <LandingPage />
          </Route> 
          <Route path="/all-works">
            <AllWorks />
          </Route> 
          <Route path="/case-study/:title"  component={CaseStudy}> 
          </Route> 
          <Route path="*">
            <P404 />
          </Route>  
        </Switch>
      </Router> 
  </main>
  );
}

export default App;
