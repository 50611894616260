import React from 'react'; 
import { Link } from "react-router-dom";
    
const TopBar = () => ( 
  <>
    <header className="site-header sticky-top py-1 shadow-sm bg-white">
      <nav className="container d-flex flex-column flex-md-row justify-content-center">
      <Link to="/landing-page/" className="d-flex justify-content-center mb-1 mt-1 text-dark text-decoration-none ">
           <img src={window.location.origin + "/assets/img/logo-glanonim.svg" } alt="glanonim icon" />
      </Link>
      </nav>
    </header>
</>
);
 
export default TopBar;