import React from "react";
import LinkedIn from "./LinkedIn";
import GenerateCurves from "../components/GenerateCurves";
import ShowWorks from "../components/ShowWorks";
import AudioTest from "../components/Audio";
import { Link } from "react-router-dom"; 
 
 

const LandingPage = () => (
  <>
    <div className="containter bg-darkpurple text-light overflow-hidden position-relative">
        <GenerateCurves/>
      <div className="p-4 p-md-5 box-text position-relative">
        <div className="col text-center my-5">
          <h1 className="display-1 BostonAngelBoldBold mt-5">Hello! Howdy?</h1>
          <h3 className="display-3 BostonAngelBoldBold mb-3">
            My name is Krzysztof <AudioTest/>
          </h3>
          <p className="mb-5">
            Tough to spell? No worries, you can call me Chris, Kristoph or just
            Kris
          </p>
        </div>
      </div>
    </div>
    <div className="containter bg-light shadow-sm text-dark overflow-hidden">
      <div className="row">
        <div className="col-12 col-md-6 p-4 p-md-5 my-5 position-relative book-box">
          <div className="book-container position-absolute top-50 start-100 translate-middle">
            <div className="book">
              <div className="back shadow-lg"></div>
              <div className="page6"></div>
              <div className="page5"></div>
              <div className="page4"></div>
              <div className="page3"></div>
              <div className="page2"></div>
              <div className="page1"></div>
              <div className="front d-flex justify-content-center align-items-center">
                <div className="bg-white px-3 pt-3 text-center rounded bg-small">
                  <h4 className="border-bottom">Yearbook</h4>
                  <p className="border-bottom">2005</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 p-4 p-md-5 my-5">
          <small className="text-muted mb-0">Personal story</small>
          <h1 className="display-1 BostonAngelBoldBold">Why glanonim?</h1>
          <p>
            The nickname glanonim is a short version of Gall Anonim (or just
            Anonymous) is the famous creator of the first polish chronicle,
            written in XII century.
          </p>
          <p>
            In secondary school I was creating the yearbook of my school and no
            one had known about it. Someone said that I was like Gall Anonim,
            who identity remains a mystery today.
          </p>
          <p>
            Somehow I liked this nickname and even created a mailbox with the
            same name. Years are passing but glanonim stays with me...
          </p>
        </div>
      </div>
    </div>
    <div className="containter bg-white">
      <div className="p-4 p-md-5">
        <LinkedIn /> 
        <div className="row">
          <div className="col col-md-6 col-lg-4 text-left">  
         See more on my <a
            href="https://www.linkedin.com/in/chris-dabrowski/"
            target="_blank"
            rel="noreferrer"
          ><button type="button" className="btn btn-secondary btn-sm">
               LinkedIn Profile
            </button></a>
          </div>
        </div>
      </div>
    </div>

    <div className="containter bg-dark border-top border-bottom p-4 p-md-5">
      <h1 className="text-white text-center pt-5 pb-4">Recent works and projects</h1>
      <div className="row justify-content-center pb-5">
        <ShowWorks showLatest="true" />
      </div>
      <div className="row justify-content-center pb-5">
      <Link to="/all-works">
        <button className="btn btn-secondary">See all</button>
      </Link>
      </div>
    </div>

    <div className="containter p-4 p-md-5 position-relative">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="row">
        <div className="col-12 ext-left py-2 py-lg-4 animation-contact">
          <h3 className="display-3 mb-2">Contact</h3>
          <h3>Let's stay in touch?</h3>
          <h5>Visit my LinkedIn profile and send me InMail there</h5>
          <a
            href="https://www.linkedin.com/in/chris-dabrowski/"
            target="_blank"
            rel="noreferrer"
          >
            <button type="button" className="btn btn-secondary btn-lg mt-3">
              Visit LinkedIn
            </button>
          </a>
          <p className="text-muted mt-2">
            Thanks to that I can save me plenty of spam and get to know you
            better
          </p>
        </div>
      </div>
    </div>
  </>
);

export default LandingPage;
