import React, { Component } from 'react'; 

class P404 extends Component {
    render() {
        return (
            <> 
            <div className="rounded shadow-sm row p0 mb-5 pb-5 bg-dark text-white" >
            <div className="col-12 pt-5 px-5">
              <small className="text-muted mb-0">Error 404</small>
              <h1 className="display-4">This page has not been found</h1>
              <p className="lead mb-0">Click logo to return to the homepage</p>
      
            </div> 
          </div> 
            </>
        );
    }
}

export default P404;