import React from 'react';  

const Wheretopark = () => {

    return (
    <>
    <div className="rounded bg-hero shadow-sm row p0 mb-4 overflow-hidden" >
      <div className="col-6 pt-5 px-5">
        <small className="text-muted mb-0">Case Study</small>
        <h1 className="display-4">WhereToPark?</h1>
        <p className="lead mb-0">April 2021</p>

      </div>
    <div className="col-6 mt-3 position-relative hero "> 
        <img src="../../assets/works/wheretopark-case-study/hero.png"
          className="" alt="Mockup showing one of application screens" /> 

      </div>
    </div>
    <div className="row justify-content-center">
      <p className="lead py-5 px-3 col-md-8  justify-content-center">
        This project was created as a third "capstone" for <a
          href="https://www.coursera.org/professional-certificates/google-ux-design" className="mx-1" target="_blank" rel="noreferrer">UX Design
          Certification</a>
        Program. The idea was to develop
        one, random idea over few weeks to learn all stages of application development process. My selected idea was to
        create a tool supporting the process of selecting terrain for a new park. Content and format of this case study
        was required by the
        course.
      </p>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3 className="pb-3">
          Welcome to WhereToPark?
          <small className="text-muted ml-2">Introduction</small>
        </h3>
        <p>My name is Krzysztof I would like to introduce you to WhereToPark? This is a tool that support the process of
          selecting area for newly-created park.</p>
        <p>I hope you will like it...
        </p>

      </div>
    </div>

    <div className="row justify-content-center py-4">
      <div className="col-md-8 justify-content-center py-3">
        <dl className="row">
          <dt className="col-sm-3 ">My role</dt>
          <dd className="col-sm-9">
            <p>I was responsible for UX reasearch, user study and design. There were no direct co-creators however I
              received a lot of support from participants of design reviews and usability studies.</p>
          </dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-3">Project timings</dt>
          <dd className="col-sm-9">April 2021</dd>
        </dl>
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>The Problem</h3>
        <p>Creation of new green areas is today manual proces and depends of politicians will. There is need to assess
          in a simple way by use geospatial data when and where new parks should be created.</p>

      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>The Goal</h3>
        <p>The goal is to create a tool that would in few steps show if and where new park should be created.</p>
      </div>
    </div>



    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Personas</h3>
        <h4 className="text-center my-3">Thomas</h4>
        <div className="row justify-content-center">
          <div className="col-sm-9 col-lg-6 mr-2">
            <dl className="row">
              <dt className="col-sm-4">Age</dt>
              <dd className="col">47</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Education</dt>
              <dd className="col">M.Sc. in Gov. Administration</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Hometown</dt>
              <dd className="col">Cologne, Germany</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Family</dt>
              <dd className="col">Married, two children</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Occupation</dt>
              <dd className="col">Employee of local office of parks and recreation</dd>
            </dl>
          </div>
          <div className="col-10 col-lg-4">
            <img src="../../assets/works/wheretopark-case-study/pexels-juan-pablo-serrano-arenas-1139743.jpg"
              className="col px-1 py-1 img-thumbnail" alt="Persona of Thomas" />

          </div>
        </div>
        <figure className="text-center">
          <blockquote className="blockquote my-5">
            <p>“I am wondering when nesting period of our falcons would finish? End of May?” </p>
          </blockquote>
        </figure>
        <div className="row justify-content-center pb-5 border-bottom">
          <div className="col bg-white border px-3 py-3 rounded mr-2">
            <p>Thomas or liek friends call him Tom, is big fan of nature. In free time he spends most of the day on
              camping, fishing or supporting local environment activities. Sometimes he takes his 2 daughters with him,
              to teach them love to the unharmed environment.</p>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
            <h4 className="text-success">Goals</h4>
            <ul>
              <li>Wants to be free and independent</li>
              <li>Wants to support enviornment and sustaibability</li>
              <li>Wants to give good start for his daughters</li>
            </ul>
            <h4 className="text-danger">Frustrations</h4>
            <ul>
              <li>Limited time</li>
              <li>Burried in paperwork and processes</li>
              <li>Tech is taking over the world</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h4 className="text-center my-3">Susanne</h4>
        <div className="row justify-content-center">
          <div className="col-sm-9 col-lg-6 mr-2">
            <dl className="row">
              <dt className="col-sm-4">Age</dt>
              <dd className="col">36</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Education</dt>
              <dd className="col">Bachelor in Environment Mgmt.</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Hometown</dt>
              <dd className="col">Cologne, Germany</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Family</dt>
              <dd className="col">Married</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4 ">Occupation</dt>
              <dd className="col">NGO employee</dd>
            </dl>
          </div>
          <div className="col-8 col-lg-3">
            <img src="../../assets/works/wheretopark-case-study/pexels-marcus-aurelius-4063537.jpg"
              className="col px-1 py-1 img-thumbnail" alt="Persona of Susanne" />

          </div>
        </div>
        <figure className="text-center">
          <blockquote className="blockquote my-5">
            <p>“There is so much to do to stop climat degradation. We must do better...“</p>
          </blockquote>
        </figure>
        <div className="row justify-content-center pb-5 border-bottom">
          <div className="col bg-white border px-3 py-3 rounded mr-2">
            <p>Susanne since she was 14 is fighting for enviornment and local community. First she worked as Greenpeace
              activist, later on her own account. She is member and active voice in many NGO, mainly connected with
              nature protection.</p>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded py-3 px-3">
            <h4 className="text-success">Goals</h4>
            <ul>
              <li>Wants to build social capital</li>
              <li>Wants to limit damage of human activities</li>
              <li>Wants to improve quality of life for everyone</li>
            </ul>
            <h4 className="text-danger">Frustrations</h4>
            <ul>
              <li>People do not care</li>
              <li>Corruption and nepotism</li>
              <li>Politicians pulling the strings</li>
              <li>No access to key data</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Competitors Analysis</h3>
        <p>No direct competitors were found. The indirect could be enviornmental agencies offering guidance, legislation
          and support on how to locate parks. Examples from US would National Park Service, <a href="http://www.nzdl.org/cgi-bin/library?e=d-00000-00---off-0hdl--00-0----0-10-0---0---0direct-10---4-------0-1l--11-en-50---20-about---00-0-1-00-0--4----0-0-11-10-0utfZz-8-00&cl=CL1.14&d=HASHb1e497b218fcc1943d0457.7&gt=1" target="_blank" rel="noreferrer">Peace Corps</a>, <a href="https://www.nrpa.org/parks-recreation-magazine/2017/march/from-concept-to-reality/" target="_blank" rel="noreferrer">NRPA</a> and <a href="https://mrsc.org/Home/Explore-Topics/Parks-and-Recreation/Parks-Open-Space-and-Trails-Planning/Park-Planning-Design-and-Open-Space.aspx" target="_blank" rel="noreferrer">MRSC</a>.</p>
        <p>Experienced architects and planners may provide most of data and knowledge on how to locate 
          park in best way. Usually this process would take several months and would require many studies.</p>
          <p>National Park Service is providing holistic framework of how the park should be designed in US:
          </p>
          <ul>
            <li>Gather basic information and background</li> 
            <li>Inventory the area in the field</li> 
            <li>Analyze the limitations and constraints</li> 
            <li>State the objectives of the park</li> 
            <li>Divide the area into management zones</li> 
            <li>Draft the boundaries for the area</li> 
            <li>Design the management programs</li> 
            <li>Prepare the integrated development program</li> 
            <li>Analyze and evaluate the plan</li> 
            <li>Design the development schedule</li>
          </ul>  
          <p>Analysis allowed to find out what aspects are relevant and therefone must be added as filters or search conditions:
          </p>
          <div className="row px-3 justify-content-center">
            <div className="col border border-primary rounded p-4 bg-white shadow-sm mr-2">
              <p className="text-primary">Location</p> 
              <ul>
                <li>Accessibility</li>
                <li>Character</li>
                <li>Safety</li>
                <li>Community needs</li>
              </ul>
            </div>
            <div className="col border border-success rounded p-4 bg-white shadow-sm">
              <p className="text-success">Amenities</p> 
              <ul> 
                <li>Fitness</li>
                <li>Playground</li>
                <li>Swimming point</li>
                <li>Dog training</li>
              </ul>
            </div>
          </div>
          <div className="row px-3 justify-content-center mt-3">
            <div className="col border border-danger rounded p-4 bg-white shadow-sm mr-2">
              <p className="text-danger">Organization</p> 
              <ul>
                <li>Grill and fire ring</li>
                <li>Bike parking</li>
                <li>Benches</li>
                <li>Litter</li>
                <li>Toilet</li>
              </ul>
            </div>
            <div className="col border border-warning rounded p-4 bg-white shadow-sm">
              <p className="text-warning">Access</p> 
              <ul>
                <li>Public transportation</li>
                <li>Bike roads</li>
                <li>Roads</li>
                <li>Natural barriers</li>
              </ul>
            </div>
          </div> 
          <div className="d-flex justify-content-center mt-3">
            <img src="../../assets/works/wheretopark-case-study/001-funnel.svg" className="col-2" alt="Funnel summarizing factors of successful park" />
           
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div className="rounded p-4 bg-white shadow-sm">
              <b className="pt-3 px-3">Successful park</b>
            </div> 
          </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">
        <h3>Research</h3>
        <p>I conducted interviews with 2 target user to understand what are pain point of working for local government.</p>
        <p>The most important discovered pain points that design process should address:</p>
          <ul>
            <li>Difficult to understand UI</li>
            <li>Too many separate logins</li>
            <li>Complicated and often unclear processed</li>
            <li>Everyone just expect results</li>
          </ul>
        
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">

        <h3>Problems Statements</h3>
        <p>By applying the user pain points I identified in LoyalFoodie study, the problem statements for John and
          Dorothea would be:</p>
        <p><u className="text-primary">Thomas</u> is a <u className="text-danger">local government employee</u> who needs <u className="text-success">a
            easy way to draft plan of new park</u> because <u className="text-warning">it is too expansive and complicated process nowadays</u>.</p>
        <p><u className="text-primary">Susanne</u> is a <u className="text-danger">supporter of local community</u> who needs <u
            className="text-success">basic tool to propose new park to the government</u> becuase <u className="text-warning">without that no effort will be started</u>.</p> 
      </div>
    </div>

    <div className="row justify-content-center">
      <div className="col-md-8 pt-3">

        <h3>Initial wireframes</h3>
      </div>
    </div>
    <p className="mt-3">Overview of wireframes created in Adobe XD:</p>
    <div className="row">
      <div className="col-12 bg-adobexd rounded border shadow-sm overflow-auto slowd-box text-center">
        <img src="../../assets/works/wheretopark-case-study/wireframes.png" className="" alt="Wireframes" />
      </div>
    </div>

    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Selection wireframe</h5>
            <p>Meetings page is intended to be the center part of daily operation. It should be the place where use would schedule new meetings, edit existing ones and preview his calendar. Calendar should be switchable between week, month or daily view with focus on user active hours.</p>
            <p>It was planned to show latest acitivites of users in selected, connected social media. That would allow for more engaging discussion, especially small talk at the beginning.</p>
          </div>
          <div className="col-8 col-lg-6">
            <img src="../../assets/works/wheretopark-case-study/wireframes-selection.png" className="img-fluid rounded"
              alt="Wireframe of Selection screen" />
          </div>
        </div>
      </div> 
    </div> 


    <div className="row justify-content-center">
      <div className="col-md-8 justify-content-center py-3">

        <h3 className="py-3">Usability Study</h3>
        <h5>Methodology</h5>
        <ul>
          <li>Unmoderated usability study</li>
          <li>Location: Online</li>
          <li>Length: Each session will last about 15 minutes, based on a list of prompts, with a SUS following the
            study</li>
          <li>Participants must be:</li>
          <ul>
            <li>Involved in park planning</li>
            <li>Be at least 35 years old</li>
            <li>At least 1 male and 1 female</li> 
          </ul>
        </ul>
        <h5>Results</h5>
        <p>Recorded results were generally summarized in 4 points:</p>
        <dl className="row">
          <dt className="col-sm-4 ">P0: How to specify location</dt>
          <dd className="col">Way to specify location was forgotten</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">P0: Missing sharing and link</dt>
          <dd className="col">Search result view will be rebuild and sharing options with link to this result will be added</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">P0: Search results are not detailed enough</dt>
          <dd className="col">Search results view will be rebuild</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">P1: Some filters are not clear</dt>
          <dd className="col">Description will be added to each filter</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">P2: Long list of results may be unclear</dt>
          <dd className="col">Pagination and relevance sorting will be added</dd>
        </dl>
        <dl className="row">
          <dt className="col-sm-4 ">P2: Cannot locate and understand import/export</dt>
          <dd className="col">Copy of import/export buttons on top will be added along with a brief explanation</dd>
        </dl> 
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-md-8 pt-3">

        <h3>High Fidelity Prototypes</h3>
  <dl className="row mt-3 pt-2 border rounded">
          <dt className="col-sm-7 pt-2">Project with high fidelity mock-ups</dt>
          <dd className="col d-grid  justify-content-center"><a
              href="https://glanonim.com/assets/works/wheretopark-case-study/WhereToPark.xd"
              target="_blank" rel="noreferrer"><button type="button" className="btn btn-dark btn-block">Download Xd file</button></a></dd>
        </dl> 
      </div>
    </div>

    <p className="mt-3">Overview of all high fidelity mock-ups:</p>
    <div className="row">
      <div className="col-12 bg-adobexd rounded border shadow-sm overflow-auto slowd-box text-center">
        <img src="../../assets/works/wheretopark-case-study/mockups.png" className="" alt="High fidelity mock-ups" />
      </div>
    </div>
 
    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Search selection mock-up</h5>
            <p>This screen was drasically reworked and extended.</p>
            <p>Each point got description. Every step was highlighted with progress bar.</p>
            <p>Location selection along with search radius was added.</p>
            <p>Additional button to import configuration was added in top bar.</p>
          </div>
          <div className="col-8 col-lg-5">
            <img src="../../assets/works/wheretopark-case-study/selection.png" className="img-fluid rounded"
              alt="Mock-up of slection screen" />
          </div>
        </div>
      </div> 
    </div> 
    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Search results mock-up</h5>
            <p>Preview of map and one search result was removed. Instead of that each result is represented equally.</p>
            <p>Most significant details about each result were added - a general score from application, number of potential visitors and size of parcel.</p>
            <p>Additionally sorting option was added, default would be sorting by score, established by application engine.</p>
          </div>
          <div className="col-8 col-lg-5">
            <img src="../../assets/works/wheretopark-case-study/results.png" className="img-fluid rounded"
              alt="Mock-up of search results screen" />
          </div>
        </div>
      </div> 
    </div> 
    <div className="row my-4 justify-content-center">
      <div className="col-12 col-lg-6 bg-adobexd rounded border shadow-sm mr-2">
        <div className="row py-3 px-2">
          <div className="col">
            <h5>Result mock-up</h5>
            <p>Separate result screen was not available in initial wireframes and was added at later point of time. It generally reflects all search criteria, displaying applicable information. It starts same way as in search results screen so that user can easily detect what page he is on.</p>
            <p>Above details location information was added, displaying evidence number of the plot of land, along with coordinates.</p>
            <p>It was planned to show latest acitivites of users in selected, connected social media. That would allow for more engaging discussion, especially small talk at the beginning.</p>
          </div>
          <div className="col-8 col-lg-5">
            <img src="../../assets/works/wheretopark-case-study/result.png" className="img-fluid rounded"
              alt="Mock-up of result screen" />
          </div>
        </div>
      </div> 
    </div> 


      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3 className="pt-3">Further Studies</h3>
          <p>For next round of usability studies the map must be interactive and highlight exact results, as the spatial services are the base of the application.</p> 
          <p>Moreover result screen must be reconsidered, to consider was results were not applied. It may be important in some aspect, for example knowing area is far away from military base.</p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Accessibility Consideration</h3>
          <p>Based on usability studies and good practice following accessibility features were planned:</p>
          <ul>
            <li>Every image and step would get additional alt text to match planned path during complete user flow</li>
            <li>Team would add high contrast option. It would be available in settings</li>
            <li>Team would add dark mode option. It would be available to switch in settings</li>

          </ul>

        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Lesson Learned</h3>
          <p>It was very interesting to work with government employees who are 50+ and see them in action. They live and work in different reality, covered in procedures, stops, passwords and forms. I wish their reality would be simplified and more focused on them, not on artificial processes.</p>
          <p>I was surprised how many databases are required to have successful application and some of them are not yet digitalized - zoning is done in most countries still paper-based. For better future we have to speed up digitalization processes.</p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-8 justify-content-center py-3">

          <h3>Credits and acknowledgments</h3>
          <p>I would like to thank to Michael, Emily, Karen, Jason, Dave, Paolo, Shabi and Kunal from Google who created
            <a href="https://www.coursera.org/professional-certificates/google-ux-design" className="mx-1" target="_blank" rel="noreferrer">UX Design
              Certification</a>
            path.
          </p>
          <p>Media credits:</p>
          <ul>
            <li>Persona photo by <a
                href="https://www.pexels.com/photo/portrait-photo-of-man-in-white-crew-neck-t-shirt-with-assorted-hand-tools-in-background-1139743/"
                target="_blank" rel="noreferrer">
                Juan Pablo Serrano Arenas</a> on Pexels
            </li>
            <li>Persona photo by <a
                href="https://www.pexels.com/photo/woman-in-yellow-crew-neck-t-shirt-sitting-on-wheelchair-4063537/"
                target="_blank" rel="noreferrer">Marcus Aurelius</a> on Pexels
            </li>

            <li>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
          </ul>
        </div>
      </div>

</>
)
}

export default Wheretopark;